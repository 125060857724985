import axiosInstance from './axios';

const addAsset = (data) => {
  return axiosInstance
    .post('asset/add/', {
      assetId: data.assetId,
      vinNumber: data.vinNumber,
      smr: data.smr,
      engineModel: data.engineModel,
      price: data.price,
      serviceInterval: data.serviceInterval,
      serviceCycle: data.serviceCycle,
      year: data.year,
      category: data.category,
      department: data.department,
      subDepartment: data.subDepartment,
      manufacture: data.manufacture,
      model: data.model,
      status: data.status,
      // interval: data.interval,
      target: data.target,
      smrType: data.smrType,
      images: '',
      user: data.user,
      engine_brand: data.engine_brand,
      engine_serial_number: data.engine_serial_number,
    })
    .then((res) => {
      return res.data;
    });
};

const allAssets = () => {
  return axiosInstance.get('asset/all/').then((res) => {
    return res.data['data'];
  });
};
const allAssetsByUserId = (id) => {
  return axiosInstance.get(`asset/all/userById/?id=${id}`).then((res) => {
    return res.data['data'];
  });
};
const assetByDepartment = ({ department }) => {
  return axiosInstance
    .post('asset/depart/', {
      department: department,
    })
    .then((res) => {
      return res.data['data'];
    });
};

const asset = ({ id, method, ...data }) => {
  if (method === 'get') {
    return axiosInstance.get(`asset/${id}/`).then((res) => {
      return res.data['data'];
    });
  } else if (method === 'patch') {
    return axiosInstance.patch(`asset/${id}/change/`, { data }).then((res) => {
      return res.data;
    });
  } else if (method === 'delete') {
    return axiosInstance.delete(`asset/${id}/`).then((res) => {
      return res.data;
    });
  } else {
    return null;
  }
};

const resetAssetSMR = ({ id, smr, prev_smr }) => {
  return axiosInstance
    .patch(`asset/reset/smr/${id}/?prev_smr=${prev_smr}`, { smr })
    .then((res) => {
      return res.data;
    });
};

const addAssetLabel = ({ name, label }) => {
  return axiosInstance
    .post(`asset/${label}/add/`, {
      name,
    })
    .then((res) => {
      return res.data;
    });
};

const allAssetLabel = (label) => {
  return axiosInstance.get(`asset/${label}/all/`).then((res) => {
    return res.data['data'];
  });
};
const AssetLabel = ({ id, label, method, ...data }) => {
  if (method === 'get') {
    return axiosInstance.get(`asset/${label}/${id}/`).then((res) => {
      return res.data;
    });
  } else if (method === 'patch') {
    return axiosInstance
      .patch(`asset/${label}/${id}/change/`, { data })
      .then((res) => {
        return res.data;
      });
  } else if (method === 'delete') {
    return axiosInstance.delete(`asset/${label}/${id}/`).then((res) => {
      return res.data;
    });
  } else {
    return null;
  }
};

const allAssetFiltered = ({ assetId, start_date, end_date }) => {
  return axiosInstance
    .get(
      `inspection/asset/?assetId=${assetId}&start_date=${start_date}&end_date=${end_date}`
    )
    .then((res) => {
      return res.data;
    });
};

const getAllAssetByDepartmentAndSmr = ({ department, smr_type }) => {
  return axiosInstance
    .post('/asset/department/web/', {
      department: department,
      smr_type: smr_type,
    })
    .then((res) => {
      return res.data['data'];
    });
};

const assetsWithNoPrestart = ({ departId, start_day }) => {
  // console.log('service Asset', departId);
  // console.log('service day', start_day);

  return axiosInstance
    .get(`asset/without/prestart/?departId=${departId}&start_day=${start_day}`)
    .then((res) => {
      return res.data['data'];
    });
};

const getAllAssetByDepartment = ({ department }) => {
  return axiosInstance
    .post('/asset/extra/department/', {
      department: department,
    })
    .then((res) => {
      return res.data['data'];
    });
};
const getAllAssetByDepartmentWeb = ({ department }) => {
  return axiosInstance
    .post('/asset/extra/department/web/', {
      department: department,
    })
    .then((res) => {
      return res.data['data'];
    });
};

const AssetService = {
  addAsset,
  allAssets,
  asset,
  addAssetLabel,
  allAssetLabel,
  AssetLabel,
  allAssetFiltered,
  assetByDepartment,
  getAllAssetByDepartmentAndSmr,
  resetAssetSMR,
  assetsWithNoPrestart,
  getAllAssetByDepartment,
  allAssetsByUserId,
  getAllAssetByDepartmentWeb,
};

export default AssetService;
