/* eslint-disable no-useless-computed-key */
import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { Instruction } from './instruction';
import { FormNav } from './formNav';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import InspectionServices from '../../../../service/inspection';
import { useForm } from 'react-hook-form';
import {
  vehicleInstructions,
  machineInstructions,
} from '../../../constant/instruction';
import { checkCategories } from '../../../../hooks/checkCategory';
import { usePerms } from '../../../../hooks/usePerms';
export const CheckList = ({
  form,
  asset,
  itemsA,
  itemsB,
  itemsC,
  itemsMachine,
}) => {
  const [assetData, setAssetData] = React.useState(asset);
  const { state } = useLocation();
  const entryRole = ['user'];
  const { role, user } = usePerms('', entryRole);

  const navigate = useNavigate();
  const [cat, setCat] = React.useState(0);

  const category = assetData['Check List']
    ? Object?.keys(assetData['Check List'])
    : {};
  const categoryQuestA = assetData['Category A']
    ? Object?.keys(assetData['Category A'])
    : {};
  const categoryQuestB = assetData['Category B']
    ? Object?.keys(assetData['Category B'])
    : {};
  const categoryQuestC = assetData['Category C']
    ? Object?.keys(assetData['Category C'])
    : {};
  const queryClient = useQueryClient();
  // const [user, setUser] = React.useState('');
  const { handleSubmit } = useForm();
  // const [isSignature, setIsSignature] = React.useState(false);

  const scoresVehicle = {
    totalA: assetData['Category A'] ? categoryQuestA.length : 0,
    totalB: assetData['Category B'] ? categoryQuestB.length : 0,
    totalC: assetData['Category C'] ? categoryQuestC.length : 0,
    total:
      assetData['Category A'] &&
      assetData['Category A'] &&
      assetData['Category A']
        ? categoryQuestA.length + categoryQuestB.length + categoryQuestC.length
        : 0,
    globalScore:
      assetData['Category A'] &&
      assetData['Category A'] &&
      assetData['Category A']
        ? Math.round(
            (((categoryQuestA.length +
              categoryQuestB.length +
              categoryQuestC.length) *
              100) /
              36) *
              100
          ) / 100
        : 0,
  };
  const handleCat = (direction, value) => {
    if (value === prestart.length - 1 && direction !== 'left') {
      navigate('prestart');
    }
    if (direction === 'front') {
      setCat(() => value + 1);
    } else {
      setCat(() => value - 1);
    }
  };
  const scoreMachine = {
    total: assetData['Category A'] ? category?.length : 0,
    globalScore: Math.round(((category?.length * 100) / 36) * 100) / 100,
  };

  const handleView = (form, cat) => {
    // setCat(0);
    if (form === 'vehicle' || form === 'folk' || form === 'truck') {
      return prestart[cat];
    } else if (form === 'machine') {
      return prestart[cat];
    }
    return null;
  };
  const formatting = (categoryTotal, categoryAns, categoryQuest) => {
    const questionsWithNoteAndMedia = categoryQuest?.filter(({ label }) => {
      const matchedAnswer = categoryAns?.find(
        (ans) => ans[label] !== undefined
      );
      return matchedAnswer && matchedAnswer.note && matchedAnswer.media;
    });
    const types = categoryQuest?.map(({ label }) => {
      const matchedAnswer = categoryAns?.find(
        (ans) => ans[label] !== undefined
      );
      return {
        label: label,
        value: matchedAnswer ? matchedAnswer[label] : 'n/a',
        note: matchedAnswer ? matchedAnswer.note || null : null,
        media: matchedAnswer ? matchedAnswer.media || null : null,
      };
    });

    return {
      total: categoryTotal,
      types: types,
      media: questionsWithNoteAndMedia.length,
    };
  };

  const { mutate, isPending, error, isError } = useMutation({
    mutationFn: InspectionServices.addPreCheck,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['inspections'] });
      navigate('/prestart');
    },
  });

  const onSubmit = () => {
    if (form === 'vehicle' || form === 'truck' || form === 'folk') {
      const {
        ['Category A']: categoryA,
        ['Category B']: categoryB,
        ['Category C']: categoryC,
        ['Extra details']: {
          Mileage,
          Comments,
          // assetKm,
          shift,
          // department,
          CreateAt,
          Operator,
          DocumentNo,
        },
        // ['Signature']: { signature },
      } = assetData;

      const catASend = formatting(scoresVehicle.totalA, categoryA, itemsA);
      const catBSend = formatting(scoresVehicle.totalB, categoryB, itemsB);
      const catCSend = formatting(scoresVehicle.totalC, categoryC, itemsC);
      const totalVehicle = scoresVehicle.total;
      const globalScoreVehicle = scoresVehicle.globalScore;
      console.log(typeof state.id);

      mutate({
        category: null,
        category_A: catASend,
        category_B: catBSend,
        category_C: catCSend,
        signature: null,
        comment: Comments,
        compressor: null,
        mileage: Mileage,
        total_items: totalVehicle,
        scores: globalScoreVehicle,
        asset: state?.id,
        user: role ? user : Operator,
        department: state?.department,
        shift: shift,
        createdAt: CreateAt,
        synced: false,
        inspectionId: DocumentNo,
      });
    } else if (form === 'machine') {
      const {
        ['Check List']: checkedList,
        ['Extra details']: {
          Comments,
          Compressor,
          Hours,
          CreateAt,
          shift,
          Operator,
          DocumentNo,
        },
        // ['Signature']: { signature },
      } = assetData;

      const catASend = formatting(
        scoreMachine.total,
        checkedList,
        itemsMachine
      );

      const totalMachine = scoreMachine.total;
      const globalScoreMachine = scoreMachine.globalScore;

      mutate({
        category: catASend,
        category_A: null,
        category_B: null,
        category_C: null,
        signature: null,
        comment: Comments,
        compressor: Compressor,
        mileage: Hours,
        total_items: totalMachine,
        scores: globalScoreMachine,
        asset: state?.id,
        user: role ? user : Operator,
        department: state?.department,
        shift: shift,
        createdAt: CreateAt,
        synced: false,
        inspectionId: DocumentNo,
      });
    }

    return;
  };

  const onClick = () => {
    if (cat !== prestart.length - 1) {
      handleCat('front', cat);
    } else {
      onSubmit();
    }
  };

  const prestart = checkCategories(
    form,
    itemsA,
    itemsB,
    itemsC,
    form === 'vehicle' || form === 'truck' || form === 'folk'
      ? scoresVehicle
      : scoreMachine,
    assetData,
    setAssetData,
    itemsMachine,
    cat,
    state
  );

  return (
    <form onSubmit={handleSubmit(onClick)}>
      <Grid
        container
        direction='column'
        sx={{
          position: 'relative',
          padding: { sm: '20px 60px' },
        }}
        gap={5}
      >
        <Grid item>
          <FormNav
            handleCategory={handleCat}
            cat={cat}
            score={
              form === 'vehicle' || form === 'folk' || form === 'truck'
                ? scoresVehicle.globalScore
                : scoreMachine.globalScore
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item container xs={12} sm={8} direction='column' gap={2}>
            <Grid
              item
              sx={{
                backgroundColor: '#2d77ab1a',
                borderRadius: '10px',
                padding: '20px 20px',
              }}
            >
              {handleView(form, cat)}
            </Grid>
            <Grid item container>
              {isError && (
                <Typography variant='caption' color='red'>
                  {error?.message}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              container
              sx={{
                justifyContent: 'flex-end',
              }}
              gap={2}
            >
              <Grid item>
                {cat > 0 && (
                  <Button
                    sx={{
                      textTransform: 'capitalize',
                    }}
                    onClick={() => handleCat('left', cat)}
                  >
                    Back
                  </Button>
                )}
              </Grid>
              <Grid item>
                <LoadingButton
                  loading={isPending}
                  variant='contained'
                  sx={{
                    textTransform: 'capitalize',
                    backgroundColor: '#2D77AB',
                    color: '#FFFFFF',
                    '&:hover': {
                      backgroundColor: '#2D77AB',
                    },
                  }}
                  type='submit'
                >
                  {cat !== prestart.length - 1 ? 'Continue' : 'Submit'}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Instruction
              asset={state}
              items={
                form === 'vehicle' || form === 'folk' || form === 'truck'
                  ? vehicleInstructions
                  : machineInstructions
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
