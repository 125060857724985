import React from 'react';
import { BasicTabView } from '../../utilities/tab';
import { CategoryComponent } from '../../components/asset/assetCategory';
import { PropTypes } from 'prop-types';
// import { AssetIntervalComponent } from '../../components/asset/assetInterval';
import { AssetManufactureComponent } from '../../components/asset/assetManufacture';
import { AssetModelComponent } from '../../components/asset/assetModel';
import { AssetStatusComponent } from '../../components/asset/assetStatus';
import { AssetComponent } from '../../components/asset';

const tab = [
  {
    id: 1,
    tabTitle: 'DataSheets',
    tabContent: <AssetComponent />,
  },
  {
    id: 2,
    tabTitle: 'Category',
    tabContent: <CategoryComponent />,
  },
  {
    id: 3,
    tabTitle: 'Manufacturer',
    tabContent: <AssetManufactureComponent />,
  },
  {
    id: 4,
    tabTitle: 'Model',
    tabContent: <AssetModelComponent />,
  },
  {
    id: 5,
    tabTitle: 'Status',
    tabContent: <AssetStatusComponent />,
  },
  // {
  //   id: 6,
  //   tabTitle: 'Interval',
  //   tabContent: <AssetIntervalComponent />,
  // },
];

export const AssetsView = () => {
  return <BasicTabView tabPanel={tab} width={'100%'} height={'300px'} />;
};

AssetsView.propTypes = {
  tab: PropTypes.array,
};
