/* eslint-disable no-undef */
const prod = {
  API_URL: `${process.env.REACT_APP_INSPECTION_LIVE_URL}`,
};

const dev = {
  // API_URL: `${process.env.REACT_APP_INSPECTION_LOCAL_URL}`,
  API_URL: `${process.env.REACT_APP_INSPECTION_LIVE_URL}`,
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
