import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RvHookupIcon from '@mui/icons-material/RvHookup';

export const AssetItems = [
  {
    id: 1,
    label: 'Vehicle & Bus',
    icon: (
      <DirectionsBusIcon fontSize='medium' sx={{ color: 'primary.light' }} />
    ),
    desc: "Let's start a new vehicle pre-check",
    path: '/vehicle-checklist',
  },
  {
    id: 2,
    label: 'Machine',
    icon: (
      <PrecisionManufacturingIcon
        fontSize='medium'
        sx={{ color: 'primary.light' }}
      />
    ),
    desc: "Let's start a new machine pre-check",
    path: '/machine-checklist',
  },
  {
    id: 3,
    label: 'Light truck',
    icon: (
      <LocalShippingIcon fontSize='medium' sx={{ color: 'primary.light' }} />
    ),
    desc: "Let's start a new light truck pre-check",
    path: '/truck-checklist',
  },
  {
    id: 4,
    label: 'FolkList, Tele Handler, Mobile Crane',
    icon: <RvHookupIcon fontSize='medium' sx={{ color: 'primary.light' }} />,
    desc: "Let's start a folkList pre-check",
    path: '/folk-list-checklist',
  },
];
