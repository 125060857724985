import React from 'react';
import { MainContent } from '../../utilities/mainContent';
import TableData from '../../utilities/table';
import {
  Grid,
  IconButton,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Drawer,
  Box,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import DeleteIcon from '@mui/icons-material/Delete';

import { MenuButtons } from '../../utilities/menuButton';
import { DrawerItem } from '../../components/prestart/drawerItem';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import InspectionServices from '../../../service/inspection';
import { DialogBox } from '../../utilities/dialog';
import { DeleteContent } from '../../../hooks/utils';
import { InspectionFilter } from '../../components/prestart/inspectionFilter';
import { useNavigate } from 'react-router-dom';
import { AssetItems } from '../../utilities/assetItems';
import { StartCapturing } from './capture';
import { usePerms } from '../../../hooks/usePerms';

const csvCols = [
  {
    key: 'inspectionId',
    label: 'Inspection ID',
  },
  {
    key: 'asset',
    label: 'Assets',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'mileage',
    label: 'Mileage',
  },

  {
    key: 'department_name',
    label: 'Department',
  },
  {
    key: 'shift',
    label: 'Shift',
  },
  {
    key: 'user_fullname',
    label: 'User',
  },
  {
    key: 'createdAt',
    label: 'CreatedAt',
  },
];

export const PrestartView = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElForm, setAnchorElForm] = React.useState(null);
  const [inspection, setInspection] = React.useState(null);
  const [search, setSearch] = React.useState(null);
  const [filteredInspection, setFilteredInspection] = React.useState(null);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isDialog, setIsDialog] = React.useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const opens = Boolean(anchorElForm);
  const entryRole = ['user'];
  const { role, user } = usePerms('', entryRole);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // const handleClickForm = (event) => {
  //   setAnchorElForm(event.currentTarget);
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseForm = () => {
    setAnchorElForm(null);
  };

  const items = [
    {
      label: 'Inspection',
      icon: <DescriptionOutlinedIcon fontSize='small' color='primary' />,
    },

    !role && {
      label: 'Delete Report',
      icon: <DeleteIcon fontSize='small' color='primary' />,
    },
  ];

  const { data, isLoading } = useQuery({
    queryKey: ['inspections', role && user],
    queryFn: () =>
      role
        ? InspectionServices.allInspectionByUser(user)
        : InspectionServices.allInspections(),
  });
  const { mutate } = useMutation({
    mutationFn: InspectionServices.inspection,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['inspections'] });
      setIsDelete(false);
    },
  });

  const [setMobileOpens, setMobileOpenssetMobileOpens] = React.useState(false);
  const [menuForm, setMenuForm] = React.useState(false);
  const toggleDrawer = () => {
    setMobileOpenssetMobileOpens(!setMobileOpens);
  };
  const toggleDrawerForm = () => {
    setMenuForm(!menuForm);
  };

  const columns = [
    {
      field: 'inspectionId',
      headerName: 'Inspection ID',
      width: 170,
    },
    {
      field: 'asset',
      headerName: 'Assets',
      width: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            backgroundColor:
              (params.value === 'PENDING' && '#002b924f') ||
              (params.value === 'COMPLETED' && '#0092844f') ||
              (params.value === 'VERIFIED' && '#7092004f') ||
              (params.value === 'DUPLICATED' && '#bcbd004f') ||
              (params.value === 'REJECTED' && '#bd00004f') ||
              (params.value === 'MODIFY' && '#FEA601') ||
              (params.value === 'RECTIFY' && '#ECDEE5'),
            padding: '5px 15px',
            minWidth: '85%',
            borderRadius: '5px',
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: 'mileage',
      headerName: 'Current SMR',
      width: 150,

      renderCell: (params) => {
        if (params.value === null) {
          return 'None';
        }
        return params.value;
      },
    },

    {
      field: 'daily_smr_average',
      headerName: 'Daily SMR Average',
      width: 170,
    },
    {
      field: 'department_name',
      headerName: 'Department',
      width: 170,
    },
    {
      field: 'shift',
      headerName: 'Shift',
      width: 140,
    },
    {
      field: 'user_fullname',
      headerName: 'Captured by',
      width: 180,
    },
    {
      field: 'createdAt',
      headerName: 'Date captured',
      width: 240,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      width: 240,
    },
    {
      field: '',
      headerName: '',
      width: 80,
      renderCell: (params) => {
        return (
          <Grid container justifyContent='flex-end'>
            <IconButton
              aria-controls={open ? 'fade-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <MenuButtons
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
              items={items.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleMenuItem(item, params)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>
                    <Typography variant='subtitle2'>{item.label}</Typography>
                  </ListItemText>
                </MenuItem>
              ))}
            />
          </Grid>
        );
      },
    },
  ];

  const handleMenuItem = (item, params) => {
    if (item.label === 'Inspection') {
      toggleDrawer();
      setAnchorEl(null);
    } else if (item.label === 'Edit Report') {
      setAnchorEl(null);
    } else if (item.label === 'Delete Report') {
      setAnchorEl(null);
      setIsDelete(true);
    } else {
      return null;
    }
  };

  const handleMenuForm = (item) => {
    if (item?.id === 1) {
      toggleDrawerForm();
      setAnchorElForm();
      navigate(item.path);
    } else if (item?.id === 2) {
      toggleDrawerForm();
      setAnchorElForm();
      navigate(item.path);
    } else if (item?.id === 3) {
      toggleDrawerForm();
      setAnchorElForm();
      navigate(item.path);
    } else if (item?.id === 4) {
      toggleDrawerForm();
      setAnchorElForm();
      navigate(item.path);
    } else {
      return null;
    }
  };

  const { mutate: inspectionFilters, isPending: inspectionFilterPending } =
    useMutation({
      mutationFn: InspectionServices.allInspectionsFiltered,
      onSuccess: (data) => {
        setFilteredInspection(data);
      },
    });

  return (
    <>
      <MainContent
        csvData={data}
        csvHeaders={csvCols}
        csvDataWithFilter={filteredInspection || search}
        csvHeadersWithFilter={csvCols}
        fileNameWithFilter={'pre-start list'}
        fileName={'pre-start list'}
        valueFrom='add_inspection'
        isBtnName={true}
        btnName={'Capture'}
        open={opens}
        onBtnNameClick={(event) => {
          // handleClickForm(event);
          setIsDialog(true);
        }}
        onChangeSearch={(val) => {
          if (val.target.value === '') {
            setSearch(() => data);
            return;
          }

          const value = data.filter(
            (item) =>
              item.inspectionId.toLowerCase().indexOf(val.target.value) > -1
          );
          setSearch(() => value);
        }}
        filterContent={
          <InspectionFilter inspectionFilters={inspectionFilters} />
        }
        isFilter={true}
        clearData={() => setFilteredInspection(null)}
        items={
          <TableData
            cols={columns}
            rows={filteredInspection || search || data}
            height='550px'
            isLoading={inspectionFilterPending || isLoading}
            onRowClick={({ row }) => {
              setInspection(() => row);
            }}
            onCellClick={({ field, row }) => {
              if (field !== '') {
                navigate(`/inspection/${row.id}/report`);
              }
            }}
          />
        }
      />
      <Drawer
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        elevation={1}
        variant='temporary'
        open={setMobileOpens}
        anchor={'right'}
        onClose={toggleDrawer}
      >
        {inspection && (
          <DrawerItem
            toggleDrawer={toggleDrawer}
            inspection={inspection != null ? inspection : null}
          />
        )}
      </Drawer>
      <DialogBox
        handleClose={() => setIsDelete(false)}
        open={isDelete}
        maxWidth='500px'
        title={
          <Typography variant='subtitle1' component='span'>
            Delete Inspection
          </Typography>
        }
        content={
          <DeleteContent
            title='Are you sure you want to delete this inspection ?'
            onCancel={() => setIsDelete(false)}
            onSubmit={() => {
              const id = inspection.id;
              const method = 'delete';
              mutate({ id, method });
            }}
          />
        }
      />

      <DialogBox
        maxWidth='300px'
        // handleClose={() => {
        //   setIsDialog(false);
        // }}
        open={isDialog}
        title={<Typography variant='subtitle1'>Asset</Typography>}
        content={<StartCapturing handleClose={() => setIsDialog(false)} />}
        submit={'Start capturing'}
      />
      <MenuButtons
        anchorEl={anchorElForm}
        open={opens}
        handleClose={handleCloseForm}
        items={AssetItems.map((item, index) => (
          <MenuItem key={index} onClick={() => handleMenuForm(item)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              primary={
                <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                  {item.label}
                </Typography>
              }
              secondary={<Typography variant='caption'>{item.desc}</Typography>}
            />
          </MenuItem>
        ))}
      />
    </>
  );
};
