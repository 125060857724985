import axios from 'axios';
import { updateStorage } from '../hooks/updateSessionStorage';

import { config } from '../config';
import { useNavigate } from 'react-router-dom';

const baseURL = config.API_URL;

const { access, refresh } =
  sessionStorage.getItem('user') !== null
    ? JSON.parse(sessionStorage.getItem('user'))
    : {};

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 900000,
  headers: {
    Authorization: access ? 'Bearer ' + access : null,
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

axiosInstance?.interceptors?.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (typeof error.response === 'undefined') {
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      originalRequest.url === baseURL + 'token/refresh/'
    ) {
      const navigate = useNavigate();
      window.location.href = `/login`;
      navigate.push('/login');

      return Promise.reject(error);
    }

    if (
      error.response.data.code === 'token_not_valid' &&
      error.response.status === 401 &&
      error.response.statusText === 'Unauthorized'
    ) {
      const refreshToken = refresh;

      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post('token/refresh/', { refresh: refreshToken })
            .then((response) => {
              updateStorage({ access: response.data.access }, 'user');

              axiosInstance.defaults.headers['Authorization'] =
                'Bearer ' + response.data.access;
              originalRequest.headers['Authorization'] =
                'Bearer ' + response.data.access;

              return axiosInstance(originalRequest);
            })
            .catch((err) => {});
        } else {
          window.location.href = `/login`;
          sessionStorage.removeItem('user');
        }
      } else {
        window.location.href = `/login`;
        sessionStorage.removeItem('user');
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
