import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';

export const Stepper = ({ message, mutate, isPending }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [input, setInput] = React.useState({
    idnumber: '',
    password: '',
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission behavior
      mutate({
        idnumber: input.idnumber,
        password: input.password,
      });
    }
  };

  const handleStepper = () => {
    return (
      <Grid container direction='column' gap={6}>
        <Grid item container direction='column' gap={2}>
          <Grid item container direction='column' gap={1}>
            <Grid item>
              <Typography variant='body2'>Email</Typography>
            </Grid>
            <Grid item>
              <TextField
                required={true}
                type='text'
                placeholder='enter email'
                fullWidth={true}
                onChange={(value) => {
                  setInput((prev) => ({
                    ...prev,
                    idnumber: value.target.value,
                  }));
                }}
                // onKeyDown={handleKeyDown}
              />
            </Grid>
          </Grid>
          <Grid item container direction='column' gap={1}>
            <Grid item>
              <Typography variant='body2'>Password</Typography>
            </Grid>
            <Grid item>
              <TextField
                id='standard-start-adornment'
                required={true}
                type={showPassword ? 'text' : 'password'}
                placeholder='enter password'
                fullWidth={true}
                onChange={(value) => {
                  setInput((prev) => ({
                    ...prev,
                    password: value.target.value,
                  }));
                }}
                error={message ? true : false}
                onKeyDown={handleKeyDown}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        onMouseUp={handleMouseUpPassword}
                        edge='end'
                        data-testid='toggle'
                      >
                        {showPassword ? (
                          <VisibilityOffIcon color='black' />
                        ) : (
                          <VisibilityIcon color='black' />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {message && (
              <Grid item xs={12} sm={12}>
                <Typography variant='body2' color='red'>
                  {message}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid container direction='column' gap={4}>
      <Grid item>{handleStepper()}</Grid>
      <Grid item>
        <LoadingButton
          fullWidth={true}
          loading={isPending}
          variant='contained'
          sx={{
            backgroundColor: 'primary.light',
            borderRadius: '10px',
            height: '50px',
          }}
          onClick={() =>
            mutate({
              idnumber: input.idnumber,
              password: input.password,
            })
          }
        >
          submit
        </LoadingButton>
        {/* )} */}
      </Grid>
    </Grid>
  );
};

Stepper.propTypes = {
  message: PropTypes.string,
  mutate: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
};
