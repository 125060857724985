import React from 'react';
import { Grid, Typography, Button, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '../../constant/alert';

export const Add = ({
  label_name,
  handleClose,
  category,
  interval,
  manufacture,
  model,
  status,
  mutationFn,
}) => {
  const { handleSubmit, register, reset } = useForm({
    defaultValues:
      category ||
      // interval
      // ||
      manufacture ||
      model ||
      status,
  });
  const queryClient = useQueryClient();
  const { mutate, isPending, data, isSuccess, error, isError } = useMutation({
    mutationFn: mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['assetCategories'],
      });
      queryClient.invalidateQueries({
        queryKey: ['assetManufacture'],
      });
      queryClient.invalidateQueries({
        queryKey: ['assetModel'],
      });
      queryClient.invalidateQueries({
        queryKey: ['assetStatus'],
      });
      // queryClient.invalidateQueries({
      //   queryKey: ['assetInterval'],
      // });
      reset();
    },
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    const { name = null } = data;
    const label = label_name.toLowerCase();

    if (category || interval || manufacture || model || status) {
      mutate({
        id:
          category?.id ||
          // interval?.id ||
          manufacture?.id ||
          model?.id ||
          status.id,
        method: 'patch',
        label,
        name,
      });
    } else {
      mutate({
        label,
        name,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isSuccess === true ? (
        <Alert text={data.message} handleClose={handleClose} />
      ) : (
        <Grid container direction={'column'} gap={4}>
          <Grid item>
            <Grid container direction={'row'} spacing={2}>
              <Grid item container direction={'column'} gap={1} xs={12} sm={12}>
                <Typography variant='subtitle2'>{label_name}</Typography>
                <TextField
                  required={true}
                  fullWidth={true}
                  sx={{
                    backgroundColor: '#FFF',
                  }}
                  size='small'
                  {...register('name')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {isError && (
              <Typography variant='caption' color='red'>
                {error['response']?.data?.message}
              </Typography>
            )}
          </Grid>
          <Grid item container spacing={3} justifyContent='flex-end'>
            <Grid item>
              <Button
                sx={{
                  textTransform: 'capitalize',
                }}
                variant='text'
                autoFocus
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                loading={isPending}
                sx={{
                  textTransform: 'capitalize',
                  // '&:hover': {},
                }}
                variant='contained'
                autoFocus
                type='submit'
              >
                {category || interval || manufacture || model || status
                  ? 'Update'
                  : 'save'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

Add.propTypes = {
  label_name: PropTypes.string,
  tab_name: PropTypes.string,
  handleClose: PropTypes.func,
  mutationFn: PropTypes.func,
  category: PropTypes.objectOf(PropTypes.shape),
  interval: PropTypes.objectOf(PropTypes.shape),
  manufacture: PropTypes.objectOf(PropTypes.shape),
};
