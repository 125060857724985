import axiosInstance from './axios';

const addIssue = (data) => {
  return axiosInstance
    .post('issue/add/', {
      name: data.name,
      description: data.description,
      needs: data.needs,
      location: '',
      category: data.category,
      images: '',
      user: data.user,
      inspection: data.id,
    })
    .then((res) => {
      return res.data;
    });
};

const allIssues = () => {
  return axiosInstance.get('issue/all/').then((res) => {
    return res.data.issues;
  });
};
const allIssueByUserId = (id) => {
  return axiosInstance.get(`issue/all/userId/?user=${id}`).then((res) => {
    return res.data.issues;
  });
};

const todayIssue = () => {
  return axiosInstance.get('issue/today/').then((res) => {
    return res.data['issues'];
  });
};
const todayIssueByUserId = (id) => {
  return axiosInstance.get(`issue/today/user/?user=${id}`).then((res) => {
    return res.data['issues'];
  });
};
const allIssueFiltered = ({ category, status, from, to }) => {
  console.log(category, status);
  return axiosInstance
    .post(
      `issue/all/?category=${category}&status=${status}&from=${from}&to=${to}`
    )
    .then((res) => {
      return res.data.issues;
    });
};

const issue = ({ id, method, ...data }) => {
  if (method === 'get') {
    return axiosInstance.get(`issue/${id}/`).then((res) => {
      return res.data;
    });
  } else if (method === 'patch') {
    return axiosInstance.patch(`issue/${id}/change/`, { data }).then((res) => {
      return res.data;
    });
  } else if (method === 'delete') {
    return axiosInstance.delete(`issue/${id}/`).then((res) => {
      return res.data;
    });
  } else {
    return null;
  }
};

const addCategory = (data) => {
  return axiosInstance
    .post('issue/category/add/', { name: data.name })
    .then((res) => {
      return res.data;
    });
};

const allCategories = () => {
  return axiosInstance.get('issue/category/all/').then((res) => {
    return res.data.categories;
  });
};

const category = ({ id, method, ...data }) => {
  if (method === 'get') {
    return axiosInstance.get(`issue/category/${id}/`).then((res) => {
      return res.data;
    });
  } else if (method === 'patch') {
    return axiosInstance
      .patch(`issue/category/${id}/change/`, { data })
      .then((res) => {
        return res.data;
      });
  } else if (method === 'delete') {
    return axiosInstance.delete(`issue/category/${id}/`).then((res) => {
      return res.data;
    });
  } else {
    return null;
  }
};

const IssueServices = {
  addIssue,
  allIssues,
  issue,
  addCategory,
  allCategories,
  category,
  allIssueFiltered,
  todayIssue,
  todayIssueByUserId,
  allIssueByUserId,
};

export default IssueServices;
