import React from 'react';
import { useNavigate, Navigate, Link } from 'react-router-dom';
import { Grid, Box, Button, Typography } from '@mui/material';
import { logo } from '../../constant/icons';
import { Stepper } from '../../components/login/steps';
import AuthService from '../../../service/auth';
import { useMutation } from '@tanstack/react-query';

export const Login = () => {
  const navigate = useNavigate();
  const [message, setmessage] = React.useState();

  const { mutate, isPending } = useMutation({
    mutationFn: AuthService.login,
    onSuccess: () => {
      navigate('/');
    },
    onError: (error) => {
      const { data } = error?.response;
      setmessage(data.message);
    },
  });

  if (sessionStorage?.getItem('user') !== null) {
    return <Navigate to='/' />;
  } else {
    return (
      <Grid
        container
        justifyContent='center'
        alignContent='center'
        sx={{
          backgroundColor: '#F9FAFF',
          height: '100vh',
          backgroundSize: 'cover',
          position: 'relative',
          backgroundImage:
            'url(https://res.cloudinary.com/josephkadjsky/image/upload/s--QQ6LmPeI--/v1712094129/banner_pjcgy4.webp)',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Grid
          item
          container
          // gap="40px"
          xs={11}
          sm={6}
          md={4}
          sx={{
            marginInline: 'auto',
            gap: { xs: '20px', sm: '40px' },
          }}
        >
          <Grid container justifyContent='center'>
            <Box
              src={logo}
              component='img'
              sx={{
                width: {
                  xs: '50%',
                  sm: '40%',
                  md: '40%',
                },
              }}
              alt='InspectFleet'
            />
          </Grid>
          <Grid
            item
            container
            gap='25px'
            xs={12}
            sm={12}
            sx={{
              padding: {
                xs: '30px 30px',
                sm: '30px 40px',
              },
              borderRadius: '10px',
              backgroundColor: { md: '#FFF' },
            }}
          >
            <Grid item xs={12} sm={12}>
              <Typography variant='body1' align='center'>
                Welcome again, to start your fleet, kindly login
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stepper
                mutate={mutate}
                isPending={isPending}
                message={message}
              />
            </Grid>
            <Grid item container justifyContent='center' xs={12} sm={12}>
              <Typography variant='caption' data-test-id='forgot'>
                Forgot password?
              </Typography>
              <Link
                to={
                  process.env.NODE_ENV === 'development'
                    ? `${process.env.REACT_APP_LOCAL_LINK_WEBSITE}`
                    : `${process.env.REACT_APP_REMOTE_LINK_WEBSITE}`
                }
                target='_blank'
              >
                <Button
                  variant='text'
                  sx={{
                    color: 'primary.light',
                    textTransform: 'capitalize',
                    paddingTop: '0px',
                    fontSize: '12px',
                  }}
                >
                  Contact support Team
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={11}
          xl={6}
          sx={{
            justifyContent: { xs: 'center', sm: 'space-between' },
            marginInline: 'auto',
            padding: '20px 0px',
            position: 'absolute',
            bottom: 0,
          }}
        >
          <Grid item>
            <Typography
              variant='caption'
              component={Link}
              to={
                process.env.NODE_ENV === 'development'
                  ? `${process.env.REACT_APP_LOCAL_LINK_WEBSITE}/privacy`
                  : `${process.env.REACT_APP_REMOTE_LINK_WEBSITE}/privacy`
              }
              target='_blank'
              sx={{
                textDecoration: 'none',
              }}
            >
              Privacy policy
            </Typography>
            |
            <Typography variant='caption'>
              &copy; InspectFleet {new Date().getFullYear()}. All right reserved
            </Typography>
          </Grid>
          <Typography variant='caption'>
            Designed and developed by &nbsp;
            <Typography
              component='a'
              variant='caption'
              color='secondary.light'
              href='https://kilamaelie.com'
              rel='noopener noreferrer'
              target='_blank'
              sx={{
                textDecoration: 'none',
              }}
            >
              Kilama Elie
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    );
  }
};
