import { Category } from '../common/components/prestart/forms/category';

export const checkCategories = (
  form,
  itemsA,
  itemsB,
  itemsC,
  scores,
  assetData,
  setAssetData,
  itemsMachine,
  cat,
  state
) => {
  if (form === 'vehicle' || form === 'folk' || form === 'truck') {
    return [
      <Category
        category='Category A'
        data={itemsA}
        score={`${scores.totalA}/${itemsA?.length}`}
        info={false}
        callback={setAssetData}
        form={form}
        assetData={assetData}
        cat={cat}
        asset={state}
      />,
      <Category
        category='Category B'
        data={itemsB}
        score={`${scores.totalB}/${itemsB.length}`}
        info={false}
        callback={setAssetData}
        form={form}
        assetData={assetData}
        cat={cat}
        asset={state}
      />,
      <Category
        category='Category C'
        data={itemsC}
        score={`${scores.totalC}/${itemsC.length}`}
        info={false}
        callback={setAssetData}
        form={form}
        assetData={assetData}
        cat={cat}
        asset={state}
      />,
      <Category
        category='Extra details'
        data={null}
        info={true}
        callback={setAssetData}
        assetData={assetData}
        form={form}
        cat={cat}
        asset={state}
      />,
    ];
  } else {
    return [
      <Category
        category='Check List'
        data={itemsMachine}
        score={`${scores?.total}/${itemsMachine.length}`}
        info={false}
        callback={setAssetData}
        assetData={assetData}
        form={form}
        cat={cat}
        asset={state}
      />,
      <Category
        category='Extra details'
        data={null}
        info={true}
        callback={setAssetData}
        assetData={assetData}
        form={form}
        cat={cat}
        asset={state}
      />,
    ];
  }
};
