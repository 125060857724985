import axiosInstance from './axios';

const addDepartment = (data) => {
  return axiosInstance
    .post('department/add/', { name: data.name, site: data.siteId })
    .then((res) => {
      return res.data;
    });
};

const allDepartments = () => {
  return axiosInstance.get('department/all/').then((res) => {
    return res.data.all;
  });
};

const allDepartmentsByUserId = (id) => {
  return axiosInstance.get(`department/all/userId/?user=${id}`).then((res) => {
    return res.data.all;
  });
};

const department = ({ id, method, ...data }) => {
  console.log(data);
  if (method === 'get') {
    return axiosInstance.get(`department/${id}/`).then((res) => {
      return res.data;
    });
  } else if (method === 'patch') {
    return axiosInstance
      .patch(`department/${id}/change/`, { data })
      .then((res) => {
        return res.data;
      });
  } else if (method === 'delete') {
    return axiosInstance.delete(`department/${id}/`).then((res) => {
      return res.data;
    });
  } else {
    return null;
  }
};
const addSubDepartment = (data) => {
  return axiosInstance
    .post('department/sub/add/', { name: data.name, department: data.departId })
    .then((res) => {
      return res.data;
    });
};

const allSubDepartments = () => {
  return axiosInstance.get('department/sub/all/').then((res) => {
    return res.data.all;
  });
};

const subDepartment = ({ id, method, ...data }) => {
  if (method === 'get') {
    return axiosInstance.get(`department/sub/${id}/`).then((res) => {
      return res.data;
    });
  } else if (method === 'patch') {
    return axiosInstance
      .patch(`department/sub/${id}/change/`, { data })
      .then((res) => {
        return res.data;
      });
  } else if (method === 'delete') {
    return axiosInstance.delete(`department/sub/${id}/`).then((res) => {
      return res.data;
    });
  } else {
    return null;
  }
};

const subDepartmentByDepartment = ({ id }) => {
  return axiosInstance.get(`department/sub/${id}/department/`).then((res) => {
    return res.data['data'];
  });
};
const subDepartmentByIdDepartments = ({ ids }) => {
  return axiosInstance.get(`department/sub/${ids}/array/`).then((res) => {
    return res.data['data'];
  });
};

const DepartmentServices = {
  addDepartment,
  allDepartments,
  department,
  addSubDepartment,
  allSubDepartments,
  subDepartment,
  subDepartmentByDepartment,
  allDepartmentsByUserId,
  subDepartmentByIdDepartments,
};

export default DepartmentServices;
