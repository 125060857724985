/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withRouter } from '../routes/withRouter';
import { Grid, Box, Typography } from '@mui/material';
import { logo } from '../constant/icons';
import { DrawerList } from '../constant/drawerItems';

// import UserServices from '../../service/user';

const DrawerView = () => {
  return (
    <Grid
      container
      direction='column'
      sx={{
        padding: '20px 10px 20px 0',
        position: 'relative',
        height: { xs: '100%', sm: '100%' },
        backgroundColor: '#f6f6f64f',
        boxShadow: '0px 0px 8px 4px #b3b3b34d',
        borderRadius: '0 15px 15px 0',
        backdropFilter: 'blur(20px)',
        transition: 'all 300ms ease-in-out',
        gap: '56px',
        '&:hover': {
          boxShadow: '0px 0px 8px 4px #2d77ab80',
        },
      }}
    >
      <Grid
        item
        container
        sx={{
          justifyContent:  'center' ,
        }}
      >
        <Box
          src={logo}
          component='img'
          sx={{
            width: {
              sm: '60%',
            },
          }}
          height={{
            xs: '50px',
            sm: 'fit-content',
          }}
          alt='InspectFleet'
        />
      </Grid>
      <Grid
        item
        sx={{
          position: 'relative',
          overflowY: 'scroll',
          maxHeight: {
            xs: 500,
          },
        }}
      >
        {DrawerList()}
      </Grid>
      <Grid
        container
        direction='row'
        spacing={1}
        alignItems='center'
        justifyContent={'center'}
        sx={{
          position: 'absolute',
          bottom: 20,
          border: 'none',
          cursor: 'pointer',
        }}
      >
        <Grid item>
          <Typography align='center' variant='caption' color='secondary.light'>
            V1.0.0
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='caption' color='secondary.light' align='center'>
            &copy; InspectFleet {new Date().getFullYear()}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(DrawerView);
