import { Divider, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { DialogBox } from '../../../utilities/dialog';
import { Content } from '../../../../hooks/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import AssetService from '../../../../service/asset';
import { usePerms } from '../../../../hooks/usePerms';

export const AssetOverview = ({ asset }) => {
  const [isSmr, setIsSmr] = React.useState(false);
  const queryClient = useQueryClient();
  const [errorAsset, setErrorAsset] = React.useState(null);
  const entryRole = ['superAdmin'];
  const { role } = usePerms('', entryRole);

  const { mutate, isPending } = useMutation({
    mutationFn: AssetService.resetAssetSMR,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['asset'] });
      queryClient.invalidateQueries({ queryKey: ['Fetch_assets'] });
    },
    onError: (error) => {
      setErrorAsset(error);
    },
  });

  console.log(role);

  return (
    <Grid container direction='column' gap={2}>
      <Grid
        item
        container
        direction='column'
        sx={{
          backgroundColor: '#FFF',
          borderRadius: '10px',
          padding: '30px 20px',
          sm: { margin: '0px 10px' },
        }}
        gap={2}
      >
        <Grid item>
          <Typography variant='h6' color='primary'>
            {asset.assetId}
          </Typography>
          <Typography variant='subtitle2' color='secondary.light'>
            {asset.catName}
          </Typography>
        </Grid>
        <Divider />
        <Grid
          item
          container
          direction='row'
          spacing={2}
          justifyContent='space-between'
        >
          <Grid item gap={1}>
            <Typography variant='subtitle2' color='secondary.light'>
              Service Interval
            </Typography>
            <Typography variant='body2' color='primary'>
              {asset.serviceInterval}
            </Typography>
            <Typography variant='subtitle2' color='secondary.light'>
              Interval
            </Typography>
            <Typography variant='body2' color='primary'>
              {asset.intervalName}
            </Typography>
          </Grid>
          <Grid item>
            <Divider
              variant='fullWidth'
              orientation='vertical'
              flexItem
              sx={{
                height: '40px',
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant='subtitle2' color='secondary.light'>
              Service Cycle
            </Typography>
            <Typography variant='body2' color='primary'>
              {asset?.serviceCycle}
            </Typography>
            {/* <Typography variant='subtitle2' color='secondary.light'>
              Reset
            </Typography>
            <Typography variant='body2' color='red'>
              0
            </Typography> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction='row'
        justifyContent='space-between'
        sx={{
          backgroundColor: '#FFF',
          borderRadius: '10px',
          padding: '30px 20px',
          sm: { margin: '0px 10px' },
        }}
      >
        <Grid item>
          <Typography variant='subtitle2' color='secondary.light'>
            Target
          </Typography>

          <Typography variant='body2' color='primary'>
            {asset.target} / per monthly
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant='subtitle2' color='secondary.light'>
            SMR
          </Typography>
          <Grid container direction='row'>
            <Typography variant='body2' color='primary'>
              {asset.smr}
            </Typography>
            {role && (
              <IconButton
                disabled={asset.smr > 0 ? false : true}
                sx={{
                  padding: '2px',
                }}
                onClick={() => setIsSmr(true)}
              >
                <RestartAltIcon
                  sx={{
                    color: 'primary.light',
                    fontSize: '18px',
                    padding: '0px',
                  }}
                />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction='column'
        sx={{
          backgroundColor: '#FFF',
          borderRadius: '10px',
          padding: '30px 20px',
          sm: { margin: '0px 10px' },
        }}
        gap={2}
      >
        <Grid item container spacing={2} justifyContent='space-between'>
          <Grid item>
            <Typography variant='subtitle2' color='secondary.light'>
              Manufacturer
            </Typography>

            <Typography variant='body2' color='primary'>
              {asset.ManufName}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant='subtitle2' color='secondary.light'>
              Model
            </Typography>

            <Typography variant='body2' color='primary'>
              {asset.engineModel} - {asset.modelName}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          item
          container
          direction='row'
          spacing={2}
          justifyContent='space-between'
        >
          <Grid item>
            <Typography variant='subtitle2' color='secondary.light'>
              Department
            </Typography>
            <Typography variant='body2' color='primary'>
              {asset.departName}
            </Typography>
          </Grid>
          <Grid item>
            <Divider
              variant='fullWidth'
              orientation='vertical'
              flexItem
              sx={{
                height: '40px',
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant='subtitle2' color='secondary.light'>
              Sub Department
            </Typography>
            <Typography variant='body2' color='primary'>
              {asset?.subDepartName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <DialogBox
        handleClose={() => setIsSmr(false)}
        open={isSmr}
        maxWidth='500px'
        title={
          <Typography variant='h6' color='primary'>
            {asset.assetId}
          </Typography>
        }
        content={
          <Content
            title={`Are you sure want to reset this asset ${asset.assetId} with  ${asset.smr} smr to 0 `}
            name={'reset'}
            onCancel={() => {
              setIsSmr(false);
              setErrorAsset(null);
            }}
            onSubmit={() => {
              const id = asset.id;
              const smr = 0;
              const prev_smr = asset.smr;
              console.log(prev_smr);

              mutate({ id, smr, prev_smr });
            }}
            setIsSmr={setIsSmr}
            isPending={isPending}
            error={errorAsset}
          />
        }
      />
    </Grid>
  );
};
