/* eslint-disable no-undef */
import React from 'react';
import { MainContent } from '../../utilities/mainContent';
import TableData from '../../utilities/table';
import { Grid, Typography, Avatar } from '@mui/material';
import { DialogBox } from '../../utilities/dialog';
import { useQuery, useMutation } from '@tanstack/react-query';
import UserServices from '../../../service/user';
import { AddUser } from './addUser';
import { UserFilter } from './userFilter';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Link } from 'react-router-dom';

const csvCols = [
  {
    key: 'idnumber',
    headerName: 'UserId',
  },
  {
    key: 'first_name',
    label: 'First Name',
  },
  {
    key: 'last_name',
    label: 'Last Name',
  },
  {
    key: 'display_name',
    label: 'Display Name',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'phone',
    label: 'cellphone',
  },
  {
    key: 'department_name',
    label: 'Department',
  },

  {
    key: 'createdAt',
    label: 'Created Date',
  },
];

export const UserComponent = () => {
  const [isDialog, setIsDialog] = React.useState(false);
  const [search, setSearch] = React.useState(null);
  const [filterUserData, setFilterUserData] = React.useState(null);
  const [user, setUser] = React.useState(null);

  const { data: users, isLoading } = useQuery({
    queryKey: ['users'],
    queryFn: () => UserServices.getAllUser(),
  });

  const columns = [
    {
      field: 'idnumber',
      headerName: 'UserId',
      width: 90,
      renderCell: (params) => {
        return (
          <Typography
            sx={{ color: 'red', fontWeight: 'semi-bold', fontSize: 12 }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'thumbnail',
      headerName: 'Photo',
      width: 150,
      renderCell: (params) => {
        return <Avatar sizes='small' />;
      },
    },
    {
      field: 'first_name',
      headerName: 'Full Name',
      width: 180,
      renderCell: (params) => {
        return (
          <Grid container spacing={1}>
            <Grid item>
              <Typography
                component={Link}
                to={`/user/detail/${params.id}`}
                sx={{
                  fontWeight: 'semi-bold',
                  textTransform: 'capitalize',
                  fontSize: 12,
                  textDecoration: 'none',
                }}
              >
                {params.value}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },

    {
      field: 'display_name',
      headerName: 'Display Name',
      width: 180,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontWeight: 'semi-bold',
              textTransform: 'capitalize',
              fontSize: 12,
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 240,
      renderCell: (params) => {
        return (
          <Typography
            noWrap
            sx={{
              fontSize: 12,
            }}
            alignSelf='center'
          >
            <Typography component='span'>
              <EmailIcon sx={{ paddingTop: '10px' }} />
              &nbsp;
            </Typography>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'phone',
      headerName: 'cellphone',
      width: 250,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: 12,
            }}
          >
            <Typography component='span'>
              <LocalPhoneIcon sx={{ paddingTop: '10px' }} />
            </Typography>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'department',
      headerName: 'Department',
      width: 150,
      renderCell: (params) => {
        return (
          <Typography
            component={Link}
            to={'/company'}
            sx={{
              fontWeight: 'semi-bold',
              textTransform: 'capitalize',
              fontSize: 12,
              textDecoration: 'none',
            }}
          >
            {`${params.value?.length} ${params.value?.length > 1 ? `Departments` : `Department`}`}
          </Typography>
        );
      },
    },

    {
      field: 'createdAt',
      headerName: 'Created Date',
      width: 240,
    },
  ];

  const { mutate: userFilters, isPending: userFilterPending } = useMutation({
    mutationFn: UserServices.getAllUserFilter,
    onSuccess: (data) => {
      setFilterUserData(data);
    },
  });

  return (
    <>
      <MainContent
        csvData={users}
        csvHeaders={csvCols}
        csvDataWithFilter={filterUserData || search}
        csvHeadersWithFilter={csvCols}
        fileNameWithFilter={'User list'}
        fileName={'User list'}
        isFilter={true}
        btnFilter='Filter  an user'
        clearData={() => setFilterUserData(null)}
        onChangeSearch={(val) => {
          if (val.target.value === '') {
            setSearch(() => users);
            return;
          }
          const value = users.filter(
            (item) =>
              item.idnumber?.toLowerCase().indexOf(val.target.value) > -1 ||
              item.display_name?.toLowerCase().indexOf(val.target.value) > -1 ||
              item.email?.toLowerCase().indexOf(val.target.value) > -1 ||
              item.first_name?.toLowerCase().indexOf(val.target.value) > -1
          );

          setSearch(value);
        }}
        title='Users'
        valueFrom={'add_user'}
        filterContent={<UserFilter userFilters={userFilters} />}
        items={
          <TableData
            cols={columns}
            rows={filterUserData || search || users}
            maxWidthMob='84vw'
            height='500px'
            isLoading={isLoading || userFilterPending}
          />
        }
        isBtnName={true}
        btnName={'Add'}
        onBtnNameClick={() => {
          setUser(null);
          setIsDialog(true);
        }}
      />
      <DialogBox
        handleClose={() => setIsDialog(false)}
        open={isDialog}
        maxWidth='500px'
        title={
          <Typography variant='subtitle1' component='span'>
            User
          </Typography>
        }
        content={<AddUser handleClose={() => setIsDialog(false)} user={user} />}
      />
    </>
  );
};
