import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import UserServices from '../service/user';

export const usePerms = (valueFrom, role) => {
  const [perms, setPerms] = React.useState(null);
  const [roles, setRoles] = React.useState(null);
  const [user, setUser] = React.useState(null);

  const { data: userDetails } = useQuery({
    queryKey: ['user', user],
    queryFn: () => user && UserServices.getUserById(user),
  });

  const getAuth = async () => {
    const { authenticated } =
      (await JSON.parse(sessionStorage.getItem('user'))) ?? {};

    const permissions =
      authenticated?.group?.map((item) => item.permissions) ?? {};
    const perms = permissions?.map((item) =>
      item?.map((item) => item.codename)
    );
    const role = authenticated?.group?.map((item) => item.name) ?? {};
    setRoles(role);
    setPerms(perms);
    setUser(authenticated?.id);
  };
  const { departments } = userDetails || [];

  useEffect(() => {
    getAuth();
  }, []);

  return {
    perms: perms?.every((item) => item?.includes(valueFrom) !== false),
    // role: roles?.some((item) => item?.includes(roleChecks) !== false),
    role: roles?.some((roleToCheck) =>
      role?.some((item) => item?.includes(roleToCheck))
    ),
    user: user,
    department: departments,
  };
};
