import React from 'react';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import { Grid, Typography, Avatar, Card } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import UserServices from '../../../service/user';
import InspectionServices from '../../../service/inspection';
import { useNavigate } from 'react-router-dom';
import AssetService from '../../../service/asset';
import { usePerms } from '../../../hooks/usePerms';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Rectangle,
  CartesianGrid,
  LabelList,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';

export const RecentView = () => {
  const entryRole = ['user'];
  const { role, user, department } = usePerms('', entryRole);
  const navigate = useNavigate();
  const { data } = useQuery({
    queryKey: ['total_items', role && user],
    queryFn: async () => {
      const users = await UserServices.getAllUser();
      const inspections = role
        ? await InspectionServices.allInspectionByUser(user)
        : await InspectionServices.allInspections();
      const assets = role
        ? await AssetService.getAllAssetByDepartmentWeb({
            department: department,
          })
        : await AssetService.allAssets();
      return {
        assets: assets?.length,
        users: users?.length,
        inspections: inspections?.length,
      };
    },
  });

  const { data: monthlyInspection, isLoading: isLoadingMonthlyInspection } =
    useQuery({
      queryKey: ['monthlyInspections'],
      queryFn: () => InspectionServices.monthlyInspections(),
    });

  const cardViewAdmin = [
    {
      id: 1,
      title: 'Assets',
      desc: 'Total assets',
      total_Items: data ? data.assets : 0,
      icon: <BarChartOutlinedIcon />,
    },
    {
      id: 2,
      title: 'Users',
      desc: 'Total users',
      total_Items: data ? data.users : 0,
      icon: <BarChartOutlinedIcon />,
    },
  ];
  const cardView = [
    {
      id: 1,
      title: 'Pre Start',
      desc: 'Total Prestarts ',
      total_Items: data ? data.inspections : 0,
      icon: <BarChartOutlinedIcon />,
    },
    {
      id: 2,
      title: 'Assets',
      desc: 'Total assets',
      total_Items: data ? data.assets : 0,
      icon: <BarChartOutlinedIcon />,
    },
  ];
  const selectedCardView = role ? cardView : cardViewAdmin;

  return (
    <Grid container direction='row' spacing={3}>
      {role === false && (
        <Grid item xs={12} sm={12} md={8}>
          <Card
            elevation={0}
            sx={{
              backgroundColor: '#ffffff6b',
              borderRadius: '10px',
              boxShadow: ' 0px 0px 20px 5px #cdcdcd4d',

              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <Grid container direction='column' gap={4}>
              <Grid
                item
                container
                sx={{
                  padding: '20px 30px',
                }}
              >
                <Typography variant='h5'>Monthly prestarts</Typography>
              </Grid>
              <Grid item>
                <ResponsiveContainer height={300}>
                  <BarChart
                    data={monthlyInspection}
                    margin={{
                      top: 20,
                      bottom: 35,
                      right: 50,
                    }}
                    radius={8}
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis
                      dataKey='month'
                      angle={25}
                      textAnchor='start'
                      interval={0}
                      dy={0}
                      tick={{ fontSize: 10 }}
                    />
                    <YAxis dataKey='total' />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar
                      dataKey='total'
                      stackId='a'
                      // label={<Typography>hh</Typography>}
                      fill='#032842'
                      activeBar={<Rectangle fill='#2E75AB' stroke='' />}
                    >
                      <LabelList
                        dataKey='total'
                        position='top'
                        angle={0}
                        offset={25}
                        fill='red'
                      />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sm={12}
        md={role === false ? 4 : 12}
        container
        direction={role === false ? 'column' : 'row'}
        gap='40px'
      >
        {selectedCardView.map((item, index) => (
          <Grid item xs={12} sm={12} md={3.6} key={index}>
            <Card
              elevation={0}
              onClick={() => {
                if (item.id === 1) {
                  navigate('/assets');
                } else if (item.id === 2) {
                  navigate('/users');
                } else {
                  navigate('/prestart/');
                }
              }}
              sx={{
                backgroundColor: '#ffffffa8',
                borderRadius: '10px',
                boxShadow: ' 0px 0px 20px 5px #cdcdcd4d',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Grid
                container
                onClick={() => {}}
                direction='column'
                sx={{
                  boxShadow: '0px 0px 20px 5px #9bbbd433',
                  backdropFilter: 'blur(20px)',
                }}
                gap={2}
              >
                <Grid
                  item
                  xs={12}
                  container
                  direction='column'
                  gap={4}
                  sx={{
                    backgroundColor: '#2d77ab0d',
                    padding: '10px 20px',
                  }}
                >
                  <Grid item container justifyContent='space-between'>
                    <Typography variant='h4' color='primary.light'>
                      {item.total_Items}
                    </Typography>
                    <Avatar sx={{ backgroundColor: '#2d77ab80' }}>
                      {item.icon}
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Typography variant='h5'>{item.title}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  sx={{
                    padding: '10px 20px',
                  }}
                >
                  <Typography variant='body2'>{item.desc}</Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
