import React from 'react';
import { Grid, Typography, Button, Avatar } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import { BasicTabView } from '../../utilities/tab';
import { Settings } from './settings';
import { Author } from './auth';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import UserServices from '../../../service/user';
import { Overview } from './overview';
import { DialogBox } from '../../utilities/dialog';
import { DeleteContent } from '../../../hooks/utils';
import { usePerms } from '../../../hooks/usePerms';

export const UserDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isDelete, setIsDelete] = React.useState(false);
  const superAdminRole = ['superAdmin'];
  const roles = ['admin', 'superAdmin'];
  const { role } = usePerms('', roles);
  const { role: SuperAdmin } = usePerms('', superAdminRole);
  const {
    data: user,
    isLoading,
    error,
    isError,
  } = useQuery({
    queryKey: ['user', id],
    queryFn: () => UserServices.getUserById(id),
  });

  const tab = [
    {
      id: 1,
      tabTitle: 'Info',
      tabContent: (
        <Settings
          user={user !== undefined && user}
          isLoading={isLoading}
          error={error}
          isError={isError}
        />
      ),
    },
    {
      id: 2,
      tabTitle: 'Authorizations',
      tabContent: (
        <Author
          user={user}
          isLoading={isLoading}
          error={error}
          isError={isError}
        />
      ),
    },
  ];
  const { mutate } = useMutation({
    mutationFn: UserServices.deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      setIsDelete(false);
    },
  });
  return (
    <Grid container direction='column' gap={5}>
      <Grid item>
        <Button
          startIcon={<WestIcon />}
          onClick={() => {
            if (role) {
              navigate('/users');
            } else {
              navigate('/');
            }
          }}
        >
          back
        </Button>
      </Grid>

      <Grid item container direction='row' spacing={2} alignItems='stretch'>
        <Grid item xs={12} sm={12} md={3} container direction='column' gap={3}>
          <Grid item>
            <Avatar
              variant='rounded'
              sx={{ width: 140, height: 140, backgroundColor: '#7c8d9f' }}
            />
          </Grid>
          <Grid
            item
            container
            direction='column'
            gap={2}
            sx={{
              overflow: 'hidden',
            }}
          >
            <Typography variant='subtitle1' fontWeight={400}>
              {isLoading ? '--' : user?.display_name}
            </Typography>
            <Typography
              variant='subtitle2'
              color='secondary.light'
              fontWeight={400}
            >
              {isLoading ? '--' : user?.email}
            </Typography>

            {SuperAdmin && (
              <Button
                disableElevation
                variant='contained'
                sx={{
                  textTransform: 'capitalize',
                  backgroundColor: '#2d77ab1a',
                  color: 'primary.main',
                  ':hover': {
                    color: 'secondary.main',
                    backgroundColor: '#2d77ab1a',
                  },
                }}
                onClick={() => setIsDelete(true)}
              >
                Delete
              </Button>
            )}
            {/* <Stack>
              <Chip label={isLoading ? '--' : user?.idnumber} />
            </Stack> */}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <BasicTabView tabPanel={tab} width={'100%'} height={'300px'} />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <Grid
            container
            sx={{
              marginTop: '40px',
              padding: '20px 20px',
              backgroundColor: '#2d77ab1a',
              overflow: 'hidden',
              display: {
                xs: 'none',
                md: 'block',
              },
              borderRadius: '10px',
            }}
          >
            <Overview user={user} />
          </Grid>
        </Grid>
      </Grid>

      <DialogBox
        handleClose={() => setIsDelete(false)}
        open={isDelete}
        maxWidth='500px'
        title={
          <Typography variant='subtitle1' component='span'>
            Delete user
          </Typography>
        }
        content={
          <DeleteContent
            title='Are you sure you want to delete this user?'
            onCancel={() => setIsDelete(false)}
            onSubmit={() => {
              const id = user.id;
              mutate({ id });
              setIsDelete(false);
              navigate('/users');
            }}
          />
        }
      />
    </Grid>
  );
};
