import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import DepartmentServices from '../../../service/department';
// import SpotServices from '../../../service/spot';
import CustomPaper from '../../utilities/customPaper';
import { useForm } from 'react-hook-form';

export const UserFilter = ({ userFilters }) => {
  const [depart, setDepart] = React.useState(null);
  // const [site, setSite] = React.useState(null);

  const { handleSubmit, register } = useForm();

  const { data: departments, isLoading: isDepartment } = useQuery({
    queryKey: ['Fetch_department'],
    queryFn: () => DepartmentServices.allDepartments(),
  });

  // const { data: sites, isLoading: isSite } = useQuery({
  //   queryKey: ['sites'],
  //   queryFn: () => SpotServices.allSpots(),
  // });

  const onSubmit = (data, e) => {
    userFilters({
      department: depart?.id || null,
      // site: site?.id || null,
      from: data.from,
      to: data.to,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction='row' spacing={2} alignContent='center'>
        <Grid item xs={12} sm={3}>
          <Typography variant='subtitle2'>Department</Typography>
          <Autocomplete
            fullWidth={true}
            size='small'
            id='combo-box-demo'
            options={!isDepartment ? departments : []}
            PaperComponent={CustomPaper}
            value={depart}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setDepart(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        {/* <Grid item xs={12} sm={3}>
          <Typography variant='subtitle2'>Site</Typography>
          <Autocomplete
            id='combo-box-demo'
            size='small'
            options={!isSite ? sites : []}
            value={site}
            fullWidth={true}
            PaperComponent={CustomPaper}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setSite(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid> */}
        <Grid item xs={12} sm={3}>
          <Typography variant='subtitle2'>From</Typography>
          <TextField
            size='small'
            fullWidth
            {...register('from')}
            type='date'
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant='subtitle2'>To</Typography>
          <TextField
            size='small'
            fullWidth
            {...register('to')}
            type='date'
            required
          />
        </Grid>
        <Grid item xs={12} sm={4} container>
          <Button
            sx={{
              textTransform: 'capitalize',
              height: '35px',
              alignSelf: 'end',
              backgroundColor: 'primary.light',
              color: 'white',
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: 'primary.light',
              },
            }}
            variant='filled'
            type='submit'
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
