import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { AssetItems } from '../../utilities/assetItems';
import React from 'react';
import DepartmentServices from '../../../service/department';
import { useQuery } from '@tanstack/react-query';
import AssetService from '../../../service/asset';
import CustomPaper from '../../utilities/customPaper';
import { useNavigate } from 'react-router-dom';
import { usePerms } from '../../../hooks/usePerms';

export const StartCapturing = ({ handleClose }) => {
  const [depart, setDepart] = React.useState(null);
  const navigate = useNavigate();
  const entryRole = ['user'];
  const { role, department } = usePerms('', entryRole);

  const [asset, setAsset] = React.useState(null);
  const { data: departments, isLoading: isDepartment } = useQuery({
    queryKey: ['department_assets'],
    queryFn: async () => {
      const resp = await DepartmentServices.allDepartments();

      return resp;
    },
  });

  const { data: allAssetDepartment, isLoading: isLoadingAsset } = useQuery({
    queryKey: ['sub_assets', role ? department : depart?.id],
    queryFn: role
      ? async () => {
          const resp = await AssetService.getAllAssetByDepartmentWeb({
            department: department,
          });
          return resp;
        }
      : async () => {
          const id = depart?.id;
          const resp = await AssetService.getAllAssetByDepartment({
            department: id,
          });
          return resp;
        },
  });

  const handleAssetType = ({ catName }) => {
    switch (catName) {
      case 'LIGHT VEHICLE' || 'BUS':
        console.log('lights vehicle or bus');

        return navigate('/vehicle-checklist', {
          state: asset,
        });
      case 'BUS':
        console.log('bus');
        return navigate('/vehicle-checklist', {
          state: asset,
        });

      case 'TELEHANDLER':
        console.log('TELEHANDLER');
        return navigate('/folk-list-checklist', {
          state: asset,
        });
      case 'MOBILE CRANE':
        console.log('MOBILE CRANE');
        return navigate('/folk-list-checklist', {
          state: asset,
        });
      case 'FORKLIFT':
        console.log('FORKLIFT');
        return navigate('/folk-list-checklist', {
          state: asset,
        });
      case 'LIGHT TRUCK':
        console.log('Light truck');

        return navigate('/truck-checklist', {
          state: asset,
        });
      case 'GENERATOR':
        console.log('Generator');
        return navigate('/machine-checklist', {
          state: asset,
        });

      default:
        return null;
    }
  };

  return (
    <Grid container direction='column' gap={4}>
      <Grid item>
        <Typography variant='h6'>
          Let's start a new pre-check any asset category
        </Typography>
      </Grid>
      <Grid item container justifyContent='space-between'>
        {AssetItems.map((item, index) => (
          <Grid item key={index}>
            <Grid
              item
              sx={{
                boxShadow: '0px 0px 20px 5px #cdcdcd4d',
                backgroundColor: '#ffffff3d',
                padding: '20px 20px',
                backdropFilter: 'blur(20px)',
                borderRadius: '10px',
              }}
            >
              {item.icon}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        direction='column'
        sx={{
          padding: '20px 20px',
          boxShadow: '0px 0px 20px 5px #cdcdcd4d',
          backgroundColor: '#ffffff3d',
          backdropFilter: 'blur(20px)',
          borderRadius: '10px',
        }}
        gap={3}
      >
        {!role && (
          <Grid item container gap={1}>
            <Typography variant='subtitle2'>Department</Typography>
            <Autocomplete
              id='combo-box-demo'
              size='small'
              options={!isDepartment ? departments : []}
              value={depart}
              fullWidth={true}
              loading={isDepartment}
              getOptionLabel={(option) => option.name}
              PaperComponent={CustomPaper}
              onChange={(event, newValue) => {
                setDepart(newValue);
              }}
              renderInput={(params) => (
                <TextField required {...params} size='small' />
              )}
            />
          </Grid>
        )}
        {role ? (
          <Grid item container gap={1}>
            <Typography variant='subtitle2'>Asset</Typography>
            <Autocomplete
              id='combo-box-demo'
              size='small'
              disabled={depart?.id !== 0 ? false : true}
              options={!isLoadingAsset ? allAssetDepartment : []}
              value={asset}
              fullWidth={true}
              loading={isLoadingAsset}
              getOptionLabel={(option) => option.assetId}
              PaperComponent={CustomPaper}
              onChange={(event, newValue) => {
                setAsset(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} required size='small' />
              )}
            />
          </Grid>
        ) : (
          depart?.id && (
            <Grid item container gap={1}>
              <Typography variant='subtitle2'>Asset</Typography>
              <Autocomplete
                id='combo-box-demo'
                size='small'
                disabled={depart?.id !== 0 ? false : true}
                options={!isLoadingAsset ? allAssetDepartment : []}
                value={asset}
                fullWidth={true}
                loading={isLoadingAsset}
                getOptionLabel={(option) => option.assetId}
                PaperComponent={CustomPaper}
                onChange={(event, newValue) => {
                  setAsset(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} required size='small' />
                )}
              />
            </Grid>
          )
        )}
      </Grid>
      <Grid item container direction={'row'} justifyContent='flex-end' gap={2}>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          disabled={asset ? false : true}
          variant='contained'
          onClick={() => {
            if (asset) {
              handleAssetType(asset);
            }
          }}
        >
          Capture
        </Button>
      </Grid>
    </Grid>
  );
};
