/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  Divider,
  Button,
  Popper,
  MenuList,
  MenuItem,
  ClickAwayListener,
  CircularProgress,
  Grow,
  ButtonGroup,
  CardMedia,
  IconButton,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InspectionServices from '../../../service/inspection';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import CustomPaper from '../../utilities/customPaper';
import TransitionAlerts from '../../constant/alertBox';
import { DialogBox } from '../../utilities/dialog';
import { SMR } from './smr';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { usePerms } from '../../../hooks/usePerms';

const options = [
  'PENDING',
  'COMPLETED',
  'DUPLICATED',
  'REJECTED',
  'MODIFY',
  'RECTIFY',
];

export const ReportOverview = ({ inspection }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [isOverview, setIsOverview] = React.useState(true);
  const [isSmr, setIsSmr] = React.useState(false);
  const entryRole = ['admin', 'superAdmin'];
  const { role } = usePerms('', entryRole);

  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: InspectionServices.inspection,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['inspection'],
      });
      queryClient.invalidateQueries({
        queryKey: ['report_inspection'],
      });
      queryClient.invalidateQueries({
        queryKey: ['inspections'],
      });
      queryClient.invalidateQueries({ queryKey: ['Fetch_assets'] });
    },
  });

  const handleClick = (event) => {
    event.preventDefault();
    mutate({
      id: inspection.id,
      method: 'patch',
      status: options[selectedIndex],
    });
  };
  const handleMenuItemClick = (event, index) => {
    event.preventDefault();
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (inspection.status === 'PENDING') {
      return setSelectedIndex(0);
    } else if (inspection.status === 'COMPLETED') {
      return setSelectedIndex(1);
    } else if (inspection.status === 'DUPLICATED') {
      return setSelectedIndex(2);
    } else if (inspection.status === 'REJECTED') {
      return setSelectedIndex(3);
    } else if (inspection.status === 'MODIFY') {
      return setSelectedIndex(4);
    } else if (inspection.status === 'RECTIFY') {
      return setSelectedIndex(5);
    }
    return null;
  }, []);

  const questions =
    inspection.category_A !== null &&
    inspection.category_B !== null &&
    inspection.category_C !== null
      ? inspection.category_A.types.length +
        inspection.category_B.types.length +
        inspection.category_C.types.length
      : inspection.category.types.length;

  return (
    <Grid item container direction={'column'} gap={3}>
      <Grid
        item
        container
        direction='column'
        sx={{
          backgroundColor: '#2d77ab1a',
          borderRadius: '10px',
          padding: '20px 20px',
        }}
        gap={3}
      >
        {error && (
          <Grid item>
            <TransitionAlerts
              title={'Error'}
              severity='error'
              data={error?.response?.data['non_field_errors'][0]}
            />
          </Grid>
        )}
        <Grid item container justifyContent='space-between'>
          <Grid item container xs={11} sm={12} md={11}>
            <Button
              startIcon={
                isOverview ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )
              }
              sx={{
                textTransform: 'capitalize',
              }}
              onClick={() => setIsOverview(!isOverview)}
            >
              Overview
            </Button>
          </Grid>
          {role && (
            <Grid
              item
              container
              justifyContent='flex-end'
              xs={12}
              sm={12}
              md={1}
            >
              <ButtonGroup
                variant='contained'
                ref={anchorRef}
                aria-label='Button group with a nested menu'
              >
                <Button
                  variant='outlined'
                  disableElevation
                  onClick={handleClick}
                >
                  {options[selectedIndex]}
                </Button>
                <Button
                  size='small'
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label='select merge strategy'
                  aria-haspopup='menu'
                  onClick={handleToggle}
                  variant='outlined'
                  sx={{
                    backgroundColor: 'transparent',
                  }}
                  disableElevation
                >
                  {isPending ? <CircularProgress /> : <ArrowDropDownIcon />}
                </Button>
              </ButtonGroup>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <CustomPaper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id='split-button-menu' autoFocusItem>
                          {options.map((option, index) => (
                            <MenuItem
                              key={option}
                              // disabled={index === 2}
                              selected={index === selectedIndex}
                              onClick={(event) =>
                                handleMenuItemClick(event, index)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </CustomPaper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          )}
        </Grid>
        {isOverview && (
          <>
            <Grid
              item
              container
              direction='column'
              sx={{
                backgroundColor: '#FFF',
                borderRadius: '10px',
                padding: '30px 20px',
                sm: { margin: '0px 10px' },
              }}
              gap={2}
            >
              <Grid item>
                <Typography variant='h6' color='primary'>
                  {inspection && inspection.asset}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='caption' color='primary'>
                  {inspection && inspection.createdAt}
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                container
                direction='row'
                spacing={2}
                justifyContent='space-between'
              >
                <Grid item>
                  <Typography variant='subtitle2' color='secondary.light'>
                    Scores
                  </Typography>
                  <Typography variant='body2' color='primary'>
                    {inspection.scores}% ( {inspection.total_items}/{questions})
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider
                    variant='fullWidth'
                    orientation='vertical'
                    flexItem
                    sx={{
                      height: '40px',
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant='subtitle2' color='secondary.light'>
                    Shift
                  </Typography>
                  <Typography variant='body2' color='primary'>
                    {inspection?.shift}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction='row'
              justifyContent='space-between'
              sx={{
                backgroundColor: '#FFF',
                borderRadius: '10px',
                padding: '30px 20px',
                sm: { margin: '0px 10px' },
              }}
            >
              <Grid item>
                <Typography variant='subtitle2' color='secondary.light'>
                  Department
                </Typography>
                {inspection && (
                  <Typography variant='body2' color='primary'>
                    {inspection?.department_name}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography variant='subtitle2' color='secondary.light'>
                  Site
                </Typography>
                {inspection && (
                  <Typography variant='body2' color='primary'>
                    {inspection.site}
                  </Typography>
                )}
              </Grid>
            </Grid>

            {inspection?.comment && (
              <Grid
                item
                container
                direction='column'
                sx={{
                  backgroundColor: '#FFF',
                  borderRadius: '10px',
                  padding: '30px 20px',
                  sm: { margin: '0px 10px' },
                }}
                gap={2}
              >
                <Grid item>
                  <Typography variant='subtitle2' color='secondary.light'>
                    Comment
                  </Typography>
                  {inspection && (
                    <Typography variant='body2' color='primary'>
                      {inspection.comment}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
            {/* {inspection.signature && ( */}
            <Grid
              item
              container
              direction='row'
              justifyContent='space-between'
              sx={{
                backgroundColor: '#FFF',
                borderRadius: '10px',
                padding: '30px 20px',
                sm: { margin: '0px 10px' },
              }}
            >
              <Grid item>
                <Typography variant='subtitle2' color='secondary.light'>
                  Signature
                </Typography>
                {inspection.signature && (
                  <CardMedia
                    image={inspection?.signature}
                    sx={{ height: 50 }}
                    component='img'
                    alt='Signature'
                  />
                )}

                {inspection && (
                  <Typography variant='body2' color='primary'>
                    {inspection.user_fullname}
                  </Typography>
                )}
              </Grid>
              <Divider
                variant='fullWidth'
                orientation='vertical'
                flexItem
                sx={{
                  height: 50,
                }}
              />
              <Grid item>
                <Typography variant='subtitle2' color='secondary.light'>
                  SMR
                </Typography>
                {inspection && (
                  <Grid container gap={1}>
                    <Typography
                      variant='body2'
                      color='primary.light'
                      fontWeight='bold'
                    >
                      {inspection?.mileage}
                    </Typography>
                    {role && (
                      <IconButton
                        sx={{
                          padding: '2px',
                        }}
                        onClick={() => setIsSmr(true)}
                      >
                        <ChangeCircleIcon
                          sx={{
                            color: 'orange',
                            fontSize: '20px',
                            padding: '0px',
                          }}
                        />
                      </IconButton>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <DialogBox
        handleClose={() => setIsSmr(false)}
        open={isSmr}
        maxWidth='500px'
        title={
          inspection && (
            <Typography variant='h6' color='primary'>
              {inspection?.inspectionId} - {inspection?.asset}
            </Typography>
          )
        }
        content={
          <SMR
            inspection={inspection && inspection}
            onCancel={() => setIsSmr(false)}
          />
        }
      />
    </Grid>
  );
};
