import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { PropTypes } from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ExportReactCSV from './csv';
import ExcelExport from './xlxs';
import { usePerms } from '../../hooks/usePerms';

export const MainContent = ({
  btnName,
  onBtnNameClick,
  isBtnName,
  items,
  onChangeSearch,
  filterContent,
  isFilter,
  isFilter2,
  filterContent2,
  csvData,
  csvHeaders,
  fileName,
  csvDataWithFilter,
  csvHeadersWithFilter,
  fileNameWithFilter,
  valueFrom,
  clearData,
  open,
  btnFilter = 'Filter',
  btnFilter2,
}) => {
  const [filter, setFilter] = React.useState(false);
  const [filter2, setFilter2] = React.useState(false);
  const entryRole = ['user'];
  const { role } = usePerms('', entryRole);

  const [auth, setAuth] = React.useState(null);
  const getAuth = async () => {
    const { authenticated } =
      (await JSON.parse(sessionStorage.getItem('user'))) ?? {};

    const permissions =
      authenticated?.group?.map((item) => item.permissions) ?? {};
    const perms = permissions?.map((item) =>
      item?.map((item) => item.codename)
    );
    setAuth(perms);
  };
  useEffect(() => {
    getAuth();
  }, []);

  return (
    <Grid container direction='column' gap={3}>
      {filter || filter2 ? null : (
        <>
          <Grid item xs={12} container direction='row' spacing={3}>
            <Grid item xs={12} sm={6} container gap='36px' alignItems='center'>
              <TextField
                placeholder='search'
                onChange={onChangeSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                size='small'
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              container
              direction='row'
              justifyContent='flex-end'
              alignItems='center'
              spacing={1}
            >
              <Grid item>
                <ExportReactCSV
                  csvData={csvData ?? []}
                  csvHeaders={csvHeaders ?? []}
                  fileName={fileName ?? ''}
                />
              </Grid>
              <Grid item>
                <ExcelExport data={csvData ?? []} fileName={fileName} />
              </Grid>
              {isBtnName &&
                valueFrom !== undefined &&
                auth?.every((item) => item?.includes(valueFrom) !== false) && (
                  <Grid item>
                    <Button
                      startIcon={<AddIcon />}
                      variant='contained'
                      aria-controls={open ? 'fade-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      sx={{
                        backgroundColor: 'primary.light',
                        borderRadius: '5px',
                        // height: '50px',
                        '&:hover': {
                          backgroundColor: 'primary.light',
                        },
                      }}
                      onClick={onBtnNameClick}
                    >
                      {btnName}
                    </Button>
                  </Grid>
                )}
            </Grid>
          </Grid>
          {!role && (
            <Grid item container direction='row'>
              {!filter && isFilter && (
                <Button
                  startIcon={<AddIcon />}
                  sx={{
                    textTransform: 'capitalize',
                    color: 'primary.light',
                  }}
                  onClick={() => setFilter(!filter)}
                >
                  {btnFilter}
                </Button>
              )}
              {!filter2 && isFilter2 && (
                <Button
                  startIcon={<AddIcon />}
                  sx={{
                    textTransform: 'capitalize',
                    color: 'primary.light',
                  }}
                  onClick={() => setFilter2(!filter2)}
                >
                  {btnFilter2}
                </Button>
              )}
            </Grid>
          )}
        </>
      )}
      {(filter || filter2) && (
        <Grid
          item
          xs={12}
          container
          direction={'column'}
          gap={2}
          sx={{
            padding: '30px 20px',
            boxShadow: '0px 0px 20px 5px #cdcdcd4d',
            backgroundColor: '#ffffff3d',
            backdropFilter: 'blur(20px)',
            borderRadius: '15px',
          }}
        >
          <Grid item container direction={'row'}>
            <Grid item xs={12} sm={6}>
              <Typography variant='h5'>Filter by: </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              container
              direction='row'
              justifyContent={'flex-end'}
              spacing={2}
            >
              <Grid item>
                <ExportReactCSV
                  csvData={csvDataWithFilter ?? []}
                  csvHeaders={csvHeadersWithFilter ?? []}
                  fileName={fileNameWithFilter ?? ''}
                />
              </Grid>
              <Grid item>
                <ExcelExport
                  data={csvDataWithFilter ?? []}
                  fileName={fileNameWithFilter ?? ''}
                />
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    if (filter === true) {
                      setFilter(!filter);
                    } else {
                      setFilter2(!filter2);
                    }

                    clearData();
                  }}
                  sx={{
                    textTransform: 'capitalize',
                    color: 'primary.light',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          {filter ? (
            <Grid item>{filterContent}</Grid>
          ) : (
            <Grid item>{filterContent2}</Grid>
          )}
        </Grid>
      )}

      <Grid item xs={12} container direction='column'>
        {items}
      </Grid>
    </Grid>
  );
};

MainContent.propTypes = {
  title: PropTypes.string,
  btnName: PropTypes.string,
  onBtnNameClick: PropTypes.func,
  isBtnName: PropTypes.bool,
  isFilter: PropTypes.bool,
  items: PropTypes.element,
  onChangeSearch: PropTypes.func,
  open: PropTypes.bool,
};
