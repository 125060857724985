import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
// import SpotServices from '../../../service/spot';
import DepartmentServices from '../../../service/department';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import UserServices from '../../../service/user';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '../../constant/alert';
import CustomPaper from '../../utilities/customPaper';

const infoInput = [
  {
    labels: 'First name',
    placeholder: 'first name',
    required: true,
    form: 'first_name',
    type: 'text',
  },
  {
    labels: 'Last name',
    placeholder: 'last name',
    required: true,
    form: 'last_name',
    type: 'text',
  },
  {
    labels: 'Display name',
    placeholder: 'display name',
    required: true,
    form: 'display_name',
    type: 'text',
  },

  {
    labels: 'Id Number',
    placeholder: 'id number',
    // required: true,
    form: 'idnumber',
    type: 'text',
  },
  {
    labels: 'Email',
    placeholder: 'email',
    required: true,
    form: 'email',
    type: 'email',
  },
  {
    labels: 'Cell phone',
    placeholder: 'tel number',
    // required: true,
    form: 'phone',
    type: 'phone',
  },
];

export const AddUser = ({ handleClose, user }) => {
  // states
  const { handleSubmit, register, reset } = useForm({ defaultValues: user });
  const [depart, setDepart] = React.useState(null);
  // const [site, setSite] = React.useState(null);
  const [group, setGroup] = React.useState(null);
  const queryClient = useQueryClient();
  // query
  const { data: departments } = useQuery({
    queryKey: ['departments'],
    queryFn: async () => {
      const data = await DepartmentServices.allDepartments();
      const defaultIndex = user
        ? data.findIndex(({ id }) => id === user.department)
        : null;
      return {
        data: data,
        defaultIndex: defaultIndex,
      };
    },
  });

  const { data: groups, isLoading } = useQuery({
    queryKey: ['groups'],
    queryFn: () => UserServices.getAllGroup(),
  });
  // mutation
  const { mutate, isPending, data, isSuccess, error, isError } = useMutation({
    mutationFn: user ? UserServices.updateUser : UserServices.addUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      reset();
      setDepart(null);
      // setSite(null);
      setGroup(null);
    },
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    const { first_name, last_name, display_name, idnumber, email, phone } =
      data;
    if (user) {
      mutate({
        id: user.id,
        method: 'patch',
        first_name,
        last_name,
        display_name,
        idnumber,
        email,
        phone,
        department: depart?.id || user.department,
      });
    } else {
      mutate({
        first_name,
        last_name,
        display_name,
        idnumber,
        email,
        phone,
        department: depart.id,
        group: group.id,
      });
    }
  };

  // console.log(error);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isSuccess === true ? (
        <Alert text={data.message} handleClose={handleClose} />
      ) : (
        <Grid container direction={'column'} gap={3}>
          <Grid item xs={12} sm={12}>
            <Grid container direction='row' spacing={2}>
              {infoInput.map((item) => (
                <Grid item xs={12} sm={6} key={item.labels}>
                  <Grid container direction='column' gap={1}>
                    <Grid item>
                      <Typography variant='body1' size='medium'>
                        {item.labels}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {
                        <TextField
                          size='small'
                          {...item}
                          // required={true}
                          fullWidth={true}
                          {...register(`${item.form}`)}
                        />
                      }
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid item container direction='column' gap={1}>
                <Grid item>
                  <Typography variant='body1'>Department</Typography>
                </Grid>
                <Grid item>
                  <Autocomplete
                    id='combo-box-demo'
                    options={departments ? departments.data : []}
                    value={
                      user
                        ? depart ||
                          departments?.data[departments.defaultIndex] ||
                          null
                        : depart || null
                    }
                    PaperComponent={CustomPaper}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setDepart(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
              </Grid>

              <Grid item container direction='column' gap={1}>
                <Grid item>
                  <Typography variant='body1'>Role</Typography>
                </Grid>
                <Grid item>
                  <Autocomplete
                    id='combo-box-demo'
                    options={groups ? groups : []}
                    PaperComponent={CustomPaper}
                    loading={isLoading}
                    value={group}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setGroup(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size='medium' />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {isError && (
              <Typography variant='caption' color='red'>
                {error?.response.data?.message}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid
              container
              direction='row'
              justifyContent='flex-end'
              spacing={2}
            >
              <Grid item>
                <Button
                  sx={{
                    textTransform: 'capitalize',
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <LoadingButton
                  loading={isPending}
                  sx={{
                    textTransform: 'capitalize',
                    backgroundColor: '#2D77AB',
                    color: '#FFFFFF',
                    '&:hover': {
                      backgroundColor: '#2D77AB',
                    },
                  }}
                  variant='contained'
                  type='submit'
                >
                  {user ? 'Update' : 'Submit'}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

AddUser.propTypes = {
  handleClose: PropTypes.func,
  user: PropTypes.objectOf(PropTypes.shape),
};
