import { Grid, Typography } from '@mui/material';
// import { usePerms } from '../../../../hooks/usePerms';

export const ExtraDetails = ({ asset }) => {
  return (
    <Grid container direction='column' gap={5}>
      <Grid item>
        <Typography variant='body1' color='primary.light'>
          Extra details
        </Typography>
      </Grid>
      <Grid item container direction='column' gap={2}>
        <Typography variant='body2' color='secondary.light'>
          Last smr
          <Typography variant='body2' color='primary.main'>
            {asset?.previous_smr}
          </Typography>
        </Typography>
        <Typography variant='body2' color='secondary.light'>
          SMR type
          <Typography variant='body2' color='primary.main'>
            {asset?.smrType}
          </Typography>
        </Typography>
        <Typography variant='body2' color='secondary.light'>
          Reset count
          <Typography variant='body2' color='primary.main'>
            {asset?.count}
          </Typography>
        </Typography>
        <Typography variant='body2' color='secondary.light'>
          Maf year
          <Typography variant='body2' color='primary.main'>
            {asset?.year}
          </Typography>
        </Typography>
        <Typography variant='body2' color='secondary.light'>
          Added by
          <Typography variant='body2' color='primary.main'>
            {asset?.fullName}
          </Typography>
        </Typography>
        <Typography variant='body2' color='secondary.light'>
          Created date
          <Typography variant='body2' color='primary.main'>
            {asset?.createdAt}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};
