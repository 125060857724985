import { Grid, Typography, List, ListItem, ListItemText } from '@mui/material';
export const Instruction = ({ items, asset }) => (
  <Grid
    container
    direction='column'
    sx={{
      backgroundColor: '#2d77ab1a',
      borderRadius: '10px',
      padding: '20px 20px',
    }}
    gap={2}
  >
    <Grid item>
      <Typography variant='body1' color='primary.light'>
        Instruction
      </Typography>
    </Grid>
    <Grid item>
      <List>
        {items.map((item, i) => (
          <ListItem key={i}>
            <ListItemText
              primary={<Typography variant='body1'>{item.label}</Typography>}
              secondary={<Typography variant='body2'>{item.desc}</Typography>}
            />
          </ListItem>
        ))}
      </List>
    </Grid>
    <Grid item>
      <Typography variant='body1' color='primary.light'>
        Asset Details
      </Typography>
    </Grid>
    <Grid item container direction='column' gap={1}>
      <Grid item>
        <Typography variant='body2'>
          Selected asset: &nbsp;
          <Typography variant='body2' color='primary.light' component='span'>
            {asset?.assetId}
          </Typography>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='body2'>
          Department: &nbsp;
          <Typography variant='body2' color='primary.light' component='span'>
            {asset?.departName}
          </Typography>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='body2'>
          Category: &nbsp;
          <Typography variant='body2' color='primary.light' component='span'>
            {asset?.catName}
          </Typography>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='body2'>
          Previous smr: &nbsp;
          <Typography variant='body2' color='primary.light' component='span'>
            {asset?.smr}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);
