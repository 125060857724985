import React from 'react';
import { MainContent } from '../../utilities/mainContent';
import TableData from '../../utilities/table';
import {
  Grid,
  IconButton,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { MenuButtons } from '../../utilities/menuButton';
// import EditIcon from '@mui/icons-material/Edit';
import { DialogBox } from '../../utilities/dialog';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { DeleteContent } from '../../../hooks/utils';
import { AddService } from '../../components/service/add';
import { ServiceFilter } from '../../components/service/filter';
import serviceServices from '../../../service/service';

const items = [
  // {
  //   label: 'Edit Service',
  //   icon: <EditIcon fontSize='small' />,
  // },
  {
    label: 'Delete Service',
    icon: <DeleteIcon fontSize='small' />,
  },
];

const csvCols = [
  {
    key: 'cat_name',
    label: 'Category',
  },
  {
    key: 'assetId_name',
    label: 'Asset ID',
  },
  {
    key: 'service_type',
    label: 'Service Type',
  },
  {
    key: 'updatedAt',
    label: 'Last Service',
  },
  {
    key: 'last_service_smr',
    label: 'Last Service Smr',
  },
  {
    key: 'next_service_smr',
    label: 'Next Service Smr',
  },
  {
    key: 'latest_smr',
    label: 'Latest Smr',
  },
  {
    key: 'smr_left',
    label: 'Smr Left',
  },
  {
    key: 'condition',
    label: 'Condition',
  },
  {
    key: 'depart_name',
    label: 'Department',
  },
  {
    key: 'sub_name',
    label: 'Sub-Department',
  },
  {
    key: 'manuf_name',
    label: 'Manufacturer',
  },
  {
    key: 'user_name',
    label: 'User',
  },

  {
    key: 'createdAt',
    label: 'Created Date',
  },
];

export const Service = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDialog, setIsDialog] = React.useState(false);
  const [search, setSearch] = React.useState(null);
  const [filterServiceData, setFilterServiceData] = React.useState(null);
  const [isDelete, setIsDelete] = React.useState(false);
  const [service, setService] = React.useState(null);
  const queryClient = useQueryClient();

  const { data: services, isLoading } = useQuery({
    queryKey: ['services'],
    queryFn: () => serviceServices.allService(),
  });

  const { mutate } = useMutation({
    mutationFn: serviceServices.service,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['services'] });
      setIsDelete(false);
    },
  });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: 'assetId_name',
      headerName: 'Asset ID',
      width: 150,
    },
    {
      field: 'service_type',
      headerName: 'Service Type',
      width: 200,
    },
    {
      field: 'updatedAt',
      headerName: 'Last Service',
      width: 180,
    },
    {
      field: 'last_service_smr',
      headerName: 'Last Service Smr',
      width: 180,
    },
    {
      field: 'next_service_smr',
      headerName: 'Next Service Smr',
      width: 200,
    },
    {
      field: 'next_service_date',
      headerName: 'Next Service date',
      width: 200,
    },
    {
      field: 'latest_smr',
      headerName: 'Latest Smr',
      width: 150,
    },
    {
      field: 'smr_left',
      headerName: 'Smr Left',
      width: 150,
    },
    {
      field: 'condition',
      headerName: 'Condition',
      width: 150,
    },
    {
      field: 'depart_name',
      headerName: 'Department',
      width: 180,
    },
    {
      field: 'sub_name',
      headerName: 'Sub-Department',
      width: 200,
    },
    {
      field: 'manuf_name',
      headerName: 'Manufacturer',
      width: 180,
    },
    {
      field: 'user_name',
      headerName: 'User',
      width: 180,
    },

    {
      field: 'createdAt',
      headerName: 'Last service date',
      width: 240,
    },

    {
      field: '',
      headerName: '',
      width: 120,
      renderCell: (params) => {
        return (
          <Grid container justifyContent='flex-end'>
            <IconButton
              aria-controls={open ? 'fade-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <MenuButtons
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
              items={items.map((item, index) => (
                <MenuItem
                  key={item.label}
                  onClick={() => handleMenuItem(item, params)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>
                    <Typography variant='subtitle2'>{item.label}</Typography>
                  </ListItemText>
                </MenuItem>
              ))}
            />
          </Grid>
        );
      },
    },
  ];

  const handleMenuItem = (item) => {
    // if (item.label === 'Edit Service') {
    //   setAnchorEl(null);
    //   setIsDialog(() => true);
    // } else
    if (item.label === 'Delete Service') {
      setAnchorEl(null);
      setIsDelete(true);
    } else {
      return null;
    }
  };
  const {
    mutate: serviceFilters,
    // data: filterServiceData,
    isPending: serviceFilterPending,
  } = useMutation({
    mutationFn: serviceServices.allServiceFiltered,
    onSuccess: (data) => {
      setFilterServiceData(data);
    },
  });

  // console.log(filterServiceData);
  return (
    <>
      <MainContent
        csvData={services}
        csvHeaders={csvCols}
        csvDataWithFilter={filterServiceData || search}
        csvHeadersWithFilter={csvCols}
        fileNameWithFilter={'Services list'}
        fileName={'Services list'}
        // isFilter={true}
        clearData={() => setFilterServiceData(null)}
        onChangeSearch={(val) => {
          if (val.target.value === '') {
            setSearch(() => services);
            return;
          }
          const value = services?.filter(
            (item) =>
              item.assetId_name?.toLowerCase().indexOf(val.target.value) > -1 ||
              item.depart_name?.toLowerCase().indexOf(val.target.value) > -1 ||
              item.manuf_name?.toLowerCase().indexOf(val.target.value) > -1 ||
              item.user_name?.toLowerCase().indexOf(val.target.value) > -1
          );

          setSearch(value);
        }}
        valueFrom={'add_service'}
        filterContent={<ServiceFilter serviceFilters={serviceFilters} />}
        items={
          <TableData
            cols={columns}
            rows={filterServiceData || search || services}
            maxWidthMob='84vw'
            height='600px'
            isLoading={serviceFilterPending || isLoading}
            onRowClick={({ row }) => setService(() => row)}
          />
        }
        isBtnName={true}
        btnName={'CAPTURE'}
        onBtnNameClick={() => {
          setService(null);
          setIsDialog(true);
          setService(services);
        }}
      />
      <DialogBox
        handleClose={() => setIsDialog(false)}
        open={isDialog}
        maxWidth='500px'
        title={
          <Typography variant='subtitle1' component='span'>
            Log New Service
          </Typography>
        }
        content={
          <AddService handleClose={() => setIsDialog(false)} service={null} />
        }
      />
      <DialogBox
        handleClose={() => setIsDelete(false)}
        open={isDelete}
        maxWidth='500px'
        title={
          <Typography variant='subtitle1' component='span'>
            Delete Service
          </Typography>
        }
        content={
          <DeleteContent
            title='Are you sure you want to delete this Service?'
            onCancel={() => setIsDelete(false)}
            onSubmit={() => {
              const id = service.id;
              mutate({ id, method: 'delete' });
            }}
          />
        }
      />
    </>
  );
};
