import { Card, Divider, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import IssueServices from '../../../service/issue';
import InspectionServices from '../../../service/inspection';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';
import { useNavigate } from 'react-router-dom';
import { usePerms } from '../../../hooks/usePerms';
export const HomeDetails = ({ user }) => {
  const { displayName, departName, id } = user ?? {};
  const navigate = useNavigate();
  const entryRole = ['user'];
  const { role } = usePerms('', entryRole);

  const {
    data: issues,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['issues_dashboard', role && id],
    queryFn: () =>
      role ? IssueServices.todayIssueByUserId(id) : IssueServices.todayIssue(),
  });

  const { data: inspections } = useQuery({
    queryKey: ['inspectionByUserId', id],
    queryFn: () =>
      role
        ? InspectionServices.getInspectionByAUserAndId(id)
        : InspectionServices.getInspectionByUserId(id),
  });

  const handleResp = (isLoading, data, isError) => {
    if (isLoading) {
      return (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid ite>
            <Typography variant='body1' color='primary'>
              Loading
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (isError) {
      return (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item>
            <Typography variant='body1' color='primary'>
              Error, could not load issues
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (data?.length <= 0) {
      return (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item>
            <Typography variant='body1' color='primary'>
              No data
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (data?.length > 0) {
      return (
        <Grid
          container
          direction='row'
          gap={2}
          sx={{
            height: '175px',
            overflowY: 'auto',
            alignItems: 'stretch',
          }}
        >
          {data?.map((item, index) => (
            <Card
              elevation={0}
              key={index}
              onClick={() => {
                navigate('/issue/', {
                  state: {
                    item: item,
                  },
                });
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                container
                direction='row'
                sx={{
                  backgroundColor: '#E3ECEF',
                  padding: '10px 10px',
                  borderRadius: '5px',
                }}
              >
                <Grid item xs={2}>
                  <ReportProblemIcon
                    sx={{
                      fontSize: 40,
                      color: '#B31314',
                    }}
                  />
                </Grid>
                <Grid item xs={10} container direction={'row'}>
                  <Grid item xs={10} container direction='column'>
                    <Typography variant='body2' color='#B31314'>
                      {item.inspection_id}
                    </Typography>

                    <Typography variant='caption' color='primary.light'>
                      {item.category_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} container justifyContent='flex-start'>
                    <Typography variant='caption' color='red'>
                      {item?.status.toLowerCase()}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} container justifyContent={'flex-end'}>
                  <Typography variant='caption' color='secondary.light'>
                    {item?.createdAt}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          ))}
        </Grid>
      );
    }
  };
  const statusList = (inspections) => [
    {
      label: 'Pending',
      value: inspections?.pending ?? 0,
      color: 'orange',
    },
    {
      label: 'Duplicated',
      value: inspections?.duplicated ?? 0,
      color: 'gray',
    },
    {
      label: 'Rejected',
      value: inspections?.rejected ?? 0,
      color: 'red',
    },
  ];

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12} sm={12} md={8}>
        <Card
          elevation={0}
          sx={{
            backgroundColor: '#ffffff6b',
            borderRadius: '10px',
            boxShadow: ' 0px 0px 20px 5px #cdcdcd4d',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <Grid
            container
            direction='column'
            sx={{
              boxShadow: '0px 0px 20px 5px #9bbbd433',
              backdropFilter: 'blur(20px)',
              padding: '40px 20px',
            }}
            gap={2}
            alignItems='stretch'
          >
            <Grid item xs={12} sm={12}>
              <Typography component='span' variant='h5'>
                Welcome back again,
              </Typography>
              &nbsp;
              <Typography component='span' variant='h5'>
                {displayName ?? '--'}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} container>
              <Grid
                item
                sx={{
                  backgroundColor: '#2D76AB',
                  padding: '5px 10px',
                  borderRadius: '5px',
                }}
              >
                <Typography variant='caption' color={'white'}>
                  {departName ?? '--'}
                </Typography>
              </Grid>
              &nbsp;
              {/* <Grid item>
                <Typography variant='h6' color='primary.light'>
                  {departName ?? '--'}
                </Typography>
              </Grid> */}
            </Grid>

            <Divider sx={{ margin: '10px 0px' }} />
            <Grid item xs={12} sm={12} container direction='row'>
              {statusList(inspections).map((item, index) => (
                <Grid
                  item
                  xs={4}
                  sm={4}
                  key={index}
                  container
                  direction='column'
                  alignItems='center'
                  gap={2}
                >
                  <Grid
                    item
                    container
                    justifyContent='center'
                    gap={1}
                    alignItems='center'
                  >
                    <Grid
                      item
                      sx={{
                        height: '15px',
                        width: '15px',
                        borderRadius: '20px',
                        backgroundColor: `${item.color}`,
                      }}
                    />
                    <Grid item>
                      <Typography> {item.label}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant='subtitle1'>{item.value}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Card
          elevation={0}
          sx={{
            backgroundColor: '#ffffff6b',
            borderRadius: '10px',
            boxShadow: ' 0px 0px 20px 5px #cdcdcd4d',

            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <Grid
            container
            direction='column'
            sx={{
              padding: '20px 10px',
              alignItems: 'stretch',
              height: '270px',
            }}
            gap={3}
          >
            <Grid item container direction='row'>
              <Grid item xs={10}>
                <Typography variant='subtitle1'>Issues</Typography>
              </Grid>
              <Grid item container xs={2} gap={1}>
                <TaxiAlertIcon />
                <Typography variant='subtitle1' color='#B31314'>
                  {issues?.length}
                </Typography>
              </Grid>
            </Grid>
            {handleResp(isLoading, issues, isError)}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
