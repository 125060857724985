import React from 'react';
import TableData from '../../utilities/table';
import { Grid, Box, Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import InspectionServices from '../../../service/inspection';
import { useNavigate } from 'react-router-dom';
import { usePerms } from '../../../hooks/usePerms';

export const IncomingView = () => {
  const entryRole = ['user'];
  const { role, user } = usePerms('', entryRole);
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    queryKey: ['today', role && user],
    queryFn: () =>
      role
        ? InspectionServices.todayInspectionsByUser(user)
        : InspectionServices.todayInspections(),
  });

  const columns = [
    {
      field: 'inspectionId',
      headerName: 'Inspection ID',
      width: 170,
    },
    {
      field: 'asset',
      headerName: 'Assets',
      width: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            backgroundColor:
              (params.value === 'PENDING' && '#002b924f') ||
              (params.value === 'COMPLETED' && '#0092844f') ||
              (params.value === 'VERIFIED' && '#7092004f') ||
              (params.value === 'DUPLICATED' && '#bcbd004f') ||
              (params.value === 'REJECTED' && '#bd00004f'),
            padding: '5px 15px',
            minWidth: '85%',
            borderRadius: '5px',
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: 'mileage',
      headerName: 'Mileage',
      width: 120,
    },

    {
      field: 'department_name',
      headerName: 'Department',
      width: 170,
    },
    {
      field: 'shift',
      headerName: 'Day Shift',
      width: 140,
    },
    {
      field: 'user_fullname',
      headerName: 'User',
      width: 140,
    },
    {
      field: 'createdAt',
      headerName: 'CreatedAt',
      width: 240,
    },
    {
      field: '',
      headerName: '',
      width: 180,
      renderCell: (params) => {
        return (
          <Grid container justifyContent='center'>
            <Button
              onClick={() => navigate(`/inspection/${params.id}/report`)}
              variant='contained'
              sx={{
                backgroundColor: '#002b924f',
                '&:hover': {
                  backgroundColor: '#002b924f',
                },
                textTransform: 'capitalize',
              }}
            >
              View report
            </Button>
          </Grid>
        );
      },
    },
  ];

  return (
    <Grid>
      <TableData
        height={'400px'}
        cols={columns}
        rows={data}
        isLoading={isLoading}
        onRowClick={({ row }) => {
          navigate(`/inspection/${row.id}/report`);
        }}
      />
    </Grid>
  );
};
